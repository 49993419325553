import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import AddHeader from '../AddHeader'
import Sidebar from '../Sidebar';
import CategoryHeader from './CategoryHeader';

function UpdateCategory(props) {
    console.log(props)
    const history = useHistory()
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [showModal,setShowModal]=useState(false)
  
    const [name,setName] = useState()
    const [slug,setSlug] = useState()
    const [id,setId]=useState()
  
    const [uname,setUName] = useState()
    const [uslug,setUSlug] = useState()
    const [uid,setUId]=useState()
  
  
    const [update,setUpdate] = useState()
    const [change,setChange] = useState()
  
  
    useEffect(()=>{
      setName(props.location.state.name)
      setSlug(props.location.state.slug)
      setId(props.location.state.id)

      setUName(props.location.state.name)
      setUSlug(props.location.state.slug)
      setUId(props.location.state.id)
    },[])
  
    const mouseover = () => {
      document.getElementById(`${props.name}`).style.backgroundColor='#F0F0F0'
    }
    const mouseout = () => {
      document.getElementById(`${props.name}`).style.backgroundColor='white'
    }
    
    const UpdateCategory = (id) => {
      return e => {
        e.preventDefault()
        if(uname===''||uslug===''){
          message.error('Empty input')
        }
        else{
        var axios = require("axios");
    
        var data = JSON.stringify({
         name:uname?uname:name,
         slug:uslug?uslug:slug
        });
    
        var config = {
          method: "put",
          url: `https://adminapi.youjifresh.com/categories/${id}`,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            message.success("Category Updated");
            setChange(false)
            setShowModal(false)
           history.push('/categories')
           
          })
          .catch(function (error) {
            console.log(error);
            message.error(error.response.data.message);
          });
      }
    }
    }
  
    const cancel = () => {
      setShowModal(false)
      setName(props.location.state.name)
      setSlug(props.location.state.slug)
  
      setUName(props.location.state.name)
      setUSlug(props.location.state.slug)
  
      if(change===true){
  
     
  
  
        window.alert("All the changes will be discarded")
  
          setUName(props.location.state.name)
          setUSlug(props.location.state.slug)
  
          setShowModal(false)
          message.warning('Changes discarded')
  
        setChange(false)
      }
      setUpdate(false)
    }


    return (
       <>
      <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <CategoryHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl p-6 md:text-3xl text-gray-800 font-bold">
                  Update Category
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Delete button */}
            
                {/* Dropdown */}
                {/* <DateSelect /> */}
                {/* Filter button */}

                {/* Add customer button */}
               
              </div>
            </div>
        

            {/* Table */}
            <div className="relative p-6 flex-auto">
                        <form id="form_add_prod">
                          <div class="flex flex-wrap -mx-2 mb-2">

                            <div class="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__product_name"
                              >
                                NAME
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__product_name"
                                type="text"
                                value={name?name:uname}
                                onChange={(e) => {
                                  setUpdate(true)
                                  if((name || uname)!==e.target.value){
                                    setChange(true)
                                  }
                                  setName();
                                  setUName(e.target.value)
                                  console.log(e.target.value);
                                }}
                                placeholder="name"
                              />
                            </div>

                            <div class="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__product_slug"
                              >
                                SLUG
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__product_slug"
                                type="text"
                                value={slug?slug:uslug}
                                onChange={(e) => {
                                  setUpdate(true)
                                  if((slug || uslug)!==e.target.value){
                                    setChange(true)
                                  }
                                  setSlug();
                                  setUSlug(e.target.value)
                                  console.log(e.target.value);
                                }}
                                placeholder="slug"
                              />
                            </div>

                    
                          </div>
                        </form>
                      </div>
                     
                      <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
                        
                        <div className="justify-items-end ">
                          <button
                            className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
                            onClick={cancel}
                          >
                            <span className="hidden xs:block">Cancel</span>
                          </button>
                          {
                            update ? 
                            <button
                            className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                            onClick={UpdateCategory(id)}
                          >
                            <span className="hidden xs:block">
                              Update 
                            </span>
                          </button>: <button
                            className="btn bg-yellow-500 opacity-50 text-white"
                            disabled
                          >
                            <span className="hidden xs:block">
                              Update 
                            </span>
                          </button>
                          }
                        </div>
                      </div>

            {/* Pagination */}
           
          </div>

          
        </main>
      </div>
    </div>


        
       
       </>
    )
}

export default UpdateCategory
