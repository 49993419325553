import React, { useState, useEffect } from "react";
import { focusHandling } from "cruip-js-toolkit";
import Category from "./CategoryTableItem";
import FilterButton from "../../components/DropdownFilter";
import { Pagination } from "antd";
import axios from "axios";

function UsersTable({ selectedItems }) {
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [fp, setFp] = useState([]);

  useEffect(() => {
    axios
      .get(`https://adminapi.youjifresh.com/categories`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        setCategory(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, fp]);

  useEffect(() => {
    focusHandling("outline");
  }, [category, fp]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (category) {
      setIsCheck(category.map((li) => li.id.toString()));
    } else if (fp) {
      setIsCheck(fp.map((li) => li.id.toString()));
    }
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  const getData = (val) => {
    console.log(val);
    setProducts();
    setFp(val);
    console.log(fp);
  };
  const get = (products) => {
    setFp([]);
    setProducts(products);
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a style={{ userSelect: "none" }}>Previous</a>;
    }
    if (type === "next") {
      return <a style={{ userSelect: "none" }}>Next</a>;
    }
    return originalElement;
  }

  const [categoryPerPage, setCategoryPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastCategory = currentPage * categoryPerPage;
  const indexofFirstCategory = indexOfLastCategory - categoryPerPage;
  const currentCategory = category.slice(
    indexofFirstCategory,
    indexOfLastCategory
  );

  const paginate = (currentPage, categoryPerPage) => {
    setCurrentPage(currentPage);
  };
  function onShowSizeChange(current, pageSize) {
    setCategoryPerPage(pageSize);
  }
  return (
    <>
      <div className=" bg-white shadow-lg rounded-sm border border-gray-200 relative">
        <header className="px-5 py-4 ">
          <h2 className="font-semibold text-gray-800">
            All Categories{" "}
            <span className="text-gray-400 font-medium p-4">
              {category && category.length}
            </span>
            {/* <FilterButton sendData={getData} clearData={get} align="left" products={products} setProducts={setProducts}/> */}
          </h2>
        </header>

        <div>
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="table-auto w-full divide-y divide-gray-200">
              {/* Table header */}
              <thead className="text-xs uppercase text-gray-500 bg-gray-50 border-t border-gray-200">
                <tr>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                    <div className="flex items-center">
                      <label className="inline-flex">
                        <span className="sr-only">Select all</span>
                        <input
                          className="form-checkbox"
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </label>
                    </div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Id</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Name</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Products</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              {category ? (
                currentCategory.map((c) => {
                  return (
                    <Category
                      key={c.id}
                      id={c.id}
                      name={c.name}
                      products={c.products}
                      handleClick={handleClick}
                      isChecked={isCheck.includes(c.id.toString())}
                      slug={c.slug}
                    />
                  );
                })
              ) : (
                <></>
              )}
              {/* {
            (fp.length>0)?fp.map((product) => {
              return (
                <Products
                  key={product.id}
                  handleClick={handleClick}
                  isChecked={isCheck.includes(product.id.toString())}
                  name={product.Name}
                  price={product.price}
                  id={product.id}
                  description={product.description}
                  category={product.category.name}
                />
              );
            }):<>
            {!products? 
               <tbody className="text-sm">
         
               <tr>
                 <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                   <div className="flex items-center">
                     <label className="inline-flex">
                      
                     </label>
                   </div>
                 </td>
                 <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                   <div className="flex items-center text-gray-800">
                     <div className="font-medium text-light-blue-500">
                      
                     </div>
                   </div>
                 </td>
                 <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                   <div>There are no Products with the applied filters</div>
                 </td>
                 <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                   <div className="font-medium text-gray-800">
                    
                   </div>
                 </td>
                 <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                   <div className="text-left font-medium text-green-500">
                     
                   </div>
                 </td>
     
                 <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                   <div className="text-left font-medium text-green-500">
                  
                   </div>
                 </td>
               </tr>
             
             </tbody>
            :<></>}
            </>} */}
            </table>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-4">
        <Pagination
          current={currentPage}
          itemRender={itemRender}
          total={category.length}
          onShowSizeChange={onShowSizeChange}
          pageSize={categoryPerPage}
          onChange={paginate}
        />
      </div>
    </>
  );
}

export default UsersTable;
