import React, { useState, useEffect } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import CouponDelete from "../partials/actions/CouponDelete";
import PaginationClassic from "../components/PaginationClassic";
import CouponTable from "../partials/coupons/CouponTable";
import { DatePicker, message } from "antd";
import axios from "axios";
import Category from "./Category";
import AddCoupon from "../partials/coupons/AddCoupon";


function Coupon() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [coupons, setCoupons] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [percentage,setPercentage]=useState()
  const [couponCode, setCouponCode] = useState("");
  const [value, setValue] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [active,setActive]=useState()
  const [maxValue,setMaxValue]=useState()

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
    console.log(selectedItems.id);
  };

  console.log(expiryDate)

  useEffect(() => {
    axios
      .get("https://adminapi.youjifresh.com/coupons", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        setCoupons(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  const AddCoupons = (e) => {
    e.preventDefault();
    if((percentage===undefined||percentage===-1)||(active===undefined||active===-1)||couponCode===''||value===undefined||(expiryDate===''||expiryDate===null)){
      message.error('Empty Field/s')
    }
    else if(coupons.map(c=>c.coupon_code).includes(couponCode)){
        message.error('A coupon with same code already exists')
    }
    else{
    var axios = require("axios");
    var data = JSON.stringify({
      Percentage:percentage,
      coupon_code: couponCode,
      Value: value,
      Expiry_Date: expiryDate,
      Active:active,
      Max_value:maxValue
    });

    var config = {
      method: "post",
      url: "https://adminapi.youjifresh.com/coupons",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        message.success("Coupon Added");
        setTimeout(() => {
          window.location.reload()
        }, 500);
      })
      .catch(function (error) {
        console.log(error);
        message.error(error.response.data.message);
      });
    setShowModal(false);
    setPercentage(-1)
    setCouponCode("");
    setValue("");
    setExpiryDate(null);
    setActive(-1)
    setMaxValue('')
  }
  };

  const clearForm = () => {
    setPercentage(-1)
    setCouponCode("");
    setValue("");
    setExpiryDate(null);
    setActive(-1)
    setMaxValue('')
  };

 
  return (
    <>
     {showModal!==true&&
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Coupons
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Delete button */}
                <CouponDelete selectedItems={selectedItems} />
                {/* Dropdown */}
                {/* <DateSelect /> */}
                {/* Filter button */}

                {/* Add customer button */}
                <button
                  className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                  onClick={() => setShowModal(true)}
                >
                  <svg
                    className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Add Coupon</span>
                </button>
              </div>
            </div>
         

            {/* Table */}
            <CouponTable selectedItems={handleSelectedItems} />

            {/* Pagination */}
            
          </div>
        </main>
      </div>
    </div>}

    {showModal ? (
              // <>
              //   <div className="justify-center items-center flex overflow-x-hidden  overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
              //     <div className="relative w-auto my-6 mx-auto max-w-3xl ">
              //       {/*content*/}
              //       <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              //         {/*header*/}
              //         <div className="flex items-start justify-between p-5 border-b border-solid bg-gray-800 border-blueGray-200 rounded-t">
              //           <h3 className="text-3xl font-semibold text-gray-400">
              //             Add Coupon
              //           </h3>
              //           <button
              //             className="p-1 ml-auto bg-transparent border-0 text-red-100 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              //             onClick={() => setShowModal(false)}
              //           >
              //             <span className="bg-transparent text-blue-600 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
              //               ×
              //             </span>
              //           </button>
              //         </div>
              //         {/*body*/}
              //         <div className="relative p-6 flex-auto">
              //           <form class="w-full max-w-lg" id="form_add_Coupon">
              //             <div class="flex flex-wrap -mx-2 mb-2">
              //               <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
              //                 <label
              //                   class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              //                   for="form__coupon_percentage"
              //                 >
              //                   PERCENTAGE
              //                 </label>
              //                 <div className='relative'>
              //               <select
              //                     class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              //                     id="form__coupon_percentage"
              //                     value={percentage}
              //                     onChange={(e) => {
              //                       setPercentage(e.target.value);
              //                       console.log(e.target.value);
              //                     }}
              //                   >
              //                     <option value={-1} >select an option</option>
              //                     <option value={true} >True</option>
              //                     <option value={false} >False</option>
                                  
                                  
              //                   </select>
              //                   </div>
              //               </div>

              //               <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
              //                 <label
              //                   class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              //                   for="form__coupon_active"
              //                 >
              //                   Active
              //                 </label>
              //                 <div className='relative'>
              //               <select
              //                     class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              //                     id="form__coupon_active"
              //                     value={active}
              //                     onChange={(e) => {
              //                       setActive(e.target.value);
              //                       console.log(e.target.value);
              //                     }}
              //                   >
              //                     <option value={-1} >select an option</option>
              //                     <option value={true} >True</option>
              //                     <option value={false} >False</option>
                                  
                                  
              //                   </select>
              //                   </div>
              //               </div>

              //               <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
              //                 <label
              //                   class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              //                   for="form__coupon_code"
              //                 >
              //                   Coupon Code
              //                 </label>
              //                 <textarea
              //                   class="appearance-none block text-clip resize-y overflow-hidden w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              //                   id="form__coupon_code"
              //                   type="text"
              //                   value={couponCode}
              //                   onChange={(e) => {
              //                     setCouponCode(e.target.value);
              //                     console.log(e.target.value);
              //                   }}
              //                   placeholder="Coupon Code"
              //                 />
              //               </div>

              //               <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
              //                 <label
              //                   class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              //                   for="form__coupon_value"
              //                 >
              //                   Value
              //                 </label>
              //                 <input
              //                   class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              //                   id="form__coupon_value"
              //                   type="number"
              //                   value={value}
              //                   onChange={(e) => {
              //                     setValue(e.target.value);
              //                     console.log(e.target.value);
              //                   }}
              //                   placeholder="ex. 10"
              //                 />
              //               </div>


              //               <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
              //                 <label
              //                   class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              //                   for="form__coupon_mvalue"
              //                 >
              //                   MAX VALUE
              //                 </label>
              //                 <input
              //                   class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              //                   id="form__coupon_mvalue"
              //                   type="number"
              //                   value={maxValue}
              //                   onChange={(e) => {
              //                     setMaxValue(e.target.value);
              //                     console.log(e.target.value);
              //                   }}
              //                   placeholder="ex. 10"
              //                 />
              //               </div>
                          

              //               <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
              //                 <label
              //                   class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              //                   for="form__coupon_exp_date"
              //                 >
              //                   Expiry Date
              //                 </label>
              //                 <div class="relative">
              //                   <DatePicker
              //                     class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              //                     id="form__coupon_exp_date"
              //                     isclearable
              //                     value={expiryDate}
              //                     onChange={(expiryDate) =>
              //                       setExpiryDate(expiryDate)
              //                     }
              //                   />
              //                 </div>
              //               </div>
              //             </div>
              //           </form>
              //         </div>
              //         {/*footer*/}
              //         <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
              //           <button
              //             onClick={clearForm}
              //             className=" btn  content-start bg-gray-400 hover:bg-gray-600 text-white mr-2"
              //           >
              //             <span className="hidden xs:block">Clear</span>
              //           </button>
              //           <div className="justify-items-end ">
              //             <button
              //               className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
              //               onClick={() => setShowModal(false)}
              //             >
              //               <span className="hidden xs:block">Cancel</span>
              //             </button>
              //             <button
              //               className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
              //               onClick={AddCoupons}
              //             >
              //               <span className="hidden xs:block">
              //                 Add
              //               </span>
              //             </button>
              //           </div>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              //   <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              // </>
              <><AddCoupon/></>
            ) : null}
    </>
  );
}

export default Coupon;
