import React, { useEffect, useState } from "react";
import { notification, message } from "antd";
import ProductImage from "./ProductImage";
import PaginationAssets from "./PaginationAssets";

var axios = require("axios");

const ProductImageListing = ({
  selectedItems,
  setPhotoId,
  setPhotoModal,
  setUrl,
  setImage,
  handleMultiplePhoto,
}) => {
  const [assets, setAssets] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [fp, setFp] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(8);

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  //   const handleClick = (e) => {
  //     const { id, checked } = e.target;
  //     setSelectAll(false);
  //     setIsCheck([...isCheck, id]);
  //     if (!checked) {
  //       setIsCheck(isCheck.filter((item) => item !== id));
  //     }
  //   };

  useEffect(() => {
    if (sessionStorage.getItem("youji_token")) {
      axios
        .get(
          "https://adminapi.youjifresh.com/upload/files?_sort=created_at:DESC&_limit=-1",
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setAssets(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          message.error(err);
        });
    }
  }, []);

  const getData = (val) => {
    console.log(val);
    setAssets();
    setFp(val);
    console.log(fp);
  };

  const get = (assets) => {
    setFp([]);
    setAssets(assets);
  };

  //  pagination..
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  return (
    <div>
      <div className=" bg-white p-16 shadow-lg rounded-sm border border-gray-200 relative">
        <header className="px-5 py-4 ">
          <h2 className="font-semibold text-lg text-gray-900">
            All assets{" "}
            <span className="text-gray-400 font-medium p-4 mx-5">
              {assets && assets.length}
            </span>
            <input
              className="h-10 w-96 pr-6 pl-5 rounded z-0 contain focus:shadow focus:outline-none"
              type="text"
              placeholder="Search Name..."
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
            {loading ? (
              <div className="flex justify-center items-center p-10">
                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-black-600"></div>
              </div>
            ) : null}
          </h2>
        </header>
        <div className="bg-white">
          <div className="max-w-2xl flex flex-column   mx-auto py-3 px-2 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              {assets
                .filter((asset) => {
                  if (searchTerm == "") {
                    return asset;
                  } else if (
                    asset.name
                      .toLowerCase()
                      .includes(searchTerm.toLocaleLowerCase())
                  ) {
                    return asset;
                  }
                })
                .map((asset) => (
                  <ProductImage
                    name={asset.name}
                    id={asset.id}
                    thumbnailUrl={asset.formats.thumbnail.url}
                    altText={asset.name}
                    setPhotoId={setPhotoId}
                    setPhotoModal={setPhotoModal}
                    setUrl={setUrl}
                    asset={asset}
                    setImage={setImage}
                    handleMultiplePhoto={handleMultiplePhoto}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductImageListing;
