import React, { useState, useEffect } from "react";
import { focusHandling } from "cruip-js-toolkit";
import Users from "./UsersTableItem";
import FilterButton from "../../components/DropdownFilter";
import { Pagination } from "antd";
import axios from "axios";

function UsersTable({ selectedItems }) {
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [fp, setFp] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState([]);
  const [filteredUser, setFilteredUser] = useState();
  const [total, setTotal] = useState();

  useEffect(() => {
    axios
      .get(`https://adminapi.youjifresh.com/users?&_limit=-1`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        setUsers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, fp]);

  useEffect(() => {
    focusHandling("outline");
  }, [users, fp]);

  const onSearch = () => {
    setFilteredUser(filteredUser);
    console.log(filteredUser);
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a style={{ userSelect: "none" }}>Previous</a>;
    }
    if (type === "next") {
      return <a style={{ userSelect: "none" }}>Next</a>;
    }
    return originalElement;
  }

  const fil = [];
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexofFirstUser = indexOfLastUser - usersPerPage;
  const currentUser = users.slice(indexofFirstUser, indexOfLastUser);

  if (searchTerm.length > 2) {
    users?.map((user) => {
      if (
        user.username.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        fil.push(user);
      }
    });
    console.log(fil);
  }

  const paginate = (currentPage, usersPerPage) => {
    setCurrentPage(currentPage);
  };

  function onShowSizeChange(current, pageSize) {
    setUsersPerPage(pageSize);
  }

  return (
    <>
      <div className=" bg-white shadow-lg rounded-sm border border-gray-200 relative">
        <header className="px-5 py-4 ">
          <h2 className="font-semibold text-gray-800">
            All Users{" "}
            <span className="text-gray-400 font-medium p-4">
              {users && users.length}
            </span>
            <input
              className="h-10  w-md-screen pr-6 pl-5 rounded z-0 contain focus:shadow focus:outline-none overflow-hidden"
              type="text"
              placeholder="Search Name..."
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />{" "}
          </h2>
        </header>

        <div>
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="table-auto w-full divide-y divide-gray-200">
              {/* Table header */}
              <thead className="text-xs uppercase text-gray-500 bg-gray-50 border-t border-gray-200">
                <tr>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Id</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Name</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Email</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              {searchTerm
                ? fil.map((user) => {
                    return (
                      <Users
                        key={user.id}
                        id={user.id}
                        username={user.username}
                        email={user.email}
                        handleClick={handleClick}
                        isChecked={isCheck.includes(user.id.toString())}
                      />
                    );
                  })
                : currentUser.map((user) => {
                    return (
                      <Users
                        key={user.id}
                        id={user.id}
                        username={user.username}
                        email={user.email}
                        handleClick={handleClick}
                        isChecked={isCheck.includes(user.id.toString())}
                      />
                    );
                  })}
            </table>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-4">
        <Pagination
          current={currentPage}
          itemRender={itemRender}
          total={users.length}
          pageSize={usersPerPage}
          onChange={paginate}
          onShowSizeChange={onShowSizeChange}
        />
      </div>
    </>
  );
}

export default UsersTable;
