import Column from "antd/lib/table/Column";
import react, { useState, useMemo } from "react";
import { useTable } from "react-table";
import { COLUMNS } from "./Columns";
import "./table.css";

function OrderItemTable({ MOCK_DATA }) {
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  console.log(MOCK_DATA);
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => MOCK_DATA, []);
  useTable({ columns: columns, data: data });

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <>
      <table {...getTableProps()} id="order">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((Column) => (
                <th {...Column.getHeaderProps()}>{Column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default OrderItemTable;
