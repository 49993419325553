import React from "react";

function UsersTableItem(props) {
 
  return (
    <>
   
     <tbody className="text-sm">
         
          <tr>
           
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="flex items-center text-gray-800">
                <div className="font-medium text-light-blue-500">
                  {props.id}
                </div>
              </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div>{props.username}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-medium text-gray-800">
                {props.email}
              </div>
            </td>
            
          </tr>
        
        </tbody>

    
      
    </>
  );
}

export default UsersTableItem;
