import React, { useEffect, useState } from "react";
import { notification, message } from "antd";
import Image from "./Image";
import PaginationAssets from "./PaginationAssets";
import { Pagination } from "antd";
var axios = require("axios");

const ImageListingComponent = ({ selectedItems, loading }) => {
  const [assets, setAssets] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [fp, setFp] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [load, setLoad] = useState(loading);

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  const handleClick = (e) => {
    const { id, checked } = e.target;
    console.log(id);
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("youji_token")) {
      axios
        .get(
          "https://adminapi.youjifresh.com/upload/files?_sort=created_at:DESC&_limit=-1",
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setAssets(res.data);
        })
        .catch((err) => {
          console.log(err);
          message.error(err);
        });
    }
  }, []);

  const getData = (val) => {
    console.log(val);
    setAssets();
    setFp(val);
    console.log(fp);
  };

  const get = (assets) => {
    setFp([]);
    setAssets(assets);
  };

  //  pagination..
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a style={{ userSelect: "none" }}>Previous</a>;
    }
    if (type === "next") {
      return <a style={{ userSelect: "none" }}>Next</a>;
    }
    return originalElement;
  }

  const [mediasPerPage, setMediasPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastMedia = currentPage * mediasPerPage;
  const indexofFirstMedia = indexOfLastMedia - mediasPerPage;
  const currentMedias = assets.slice(indexofFirstMedia, indexOfLastMedia);

  const paginate = (currentPage, mediasPerPage) => {
    setCurrentPage(currentPage);
  };

  function onShowSizeChange(current, pageSize) {
    setMediasPerPage(pageSize);
  }

  const fil = [];

  if (searchTerm.length > 2) {
    assets?.map((asset) => {
      if (asset.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
        fil.push(asset);
      }
    });
    console.log(fil);
  }

  // console.log(assets);
  return (
    <>
      <div>
        <div className=" bg-white shadow-lg rounded-sm border border-gray-200 relative">
          <header className="px-5 py-4 ">
            <h2 className="font-semibold text-lg text-gray-900">
              All assets{" "}
              <span className="text-gray-400 font-medium p-4 mx-5">
                {assets && assets.length}
              </span>
              <input
                className="h-10  w-md-screen pr-6 pl-5 rounded z-0 contain focus:shadow focus:outline-none overflow-hidden"
                type="text"
                placeholder="Search Name..."
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
              {loading ? (
                <div className="flex justify-center items-center p-10">
                  <div className="animate-spin text-yellow-500 rounded-full h-10 w-10 border-b-2 border-black-600"></div>
                </div>
              ) : null}
            </h2>
          </header>
          <div className="bg-white">
            <div className="max-w-2xl flex flex-column   mx-auto py-3 px-2 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
              <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                {searchTerm
                  ? fil.map((asset) => {
                      return (
                        <Image
                          name={asset.name}
                          handleClick={handleClick}
                          isChecked={isCheck.includes(asset.id.toString())}
                          id={asset.id}
                          thumbnailUrl={
                            asset.formats.thumbnail.url
                              ? asset.formats.thumbnail.url
                              : asset.url
                          }
                          altText={asset.name}
                          mime={asset.mime}
                          url={asset.url}
                          onClick={() => {
                            console.log(asset.id);
                          }}
                        />
                      );
                    })
                  : // .filter((asset) => {
                    //   if (searchTerm == "") {
                    //     return asset;
                    //   } else if (
                    //     asset.name
                    //       .toLowerCase()
                    //       .includes(searchTerm.toLocaleLowerCase())
                    //   ) {
                    //     return asset;
                    //   }
                    // })
                    currentMedias.map((asset) => (
                      <Image
                        name={asset.name}
                        handleClick={handleClick}
                        isChecked={isCheck.includes(asset.id.toString())}
                        id={asset.id}
                        thumbnailUrl={
                          asset.formats.thumbnail.url
                            ? asset.formats.thumbnail.url
                            : asset.url
                        }
                        altText={asset.name}
                        mime={asset.mime}
                        url={asset.url}
                        onClick={() => {
                          console.log(asset.id);
                        }}
                      />
                    ))}
              </div>
            </div>
          </div>
        </div>
        {/* Pagination */}
      </div>
      <div className="mt-4 mb-4 w-fit-content">
        <Pagination
          current={currentPage}
          itemRender={itemRender}
          total={assets.length}
          pageSize={mediasPerPage}
          onChange={paginate}
          onShowSizeChange={onShowSizeChange}
        />
      </div>
    </>
  );
};

export default ImageListingComponent;
