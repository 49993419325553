import React, { useState, useEffect } from "react";
import { focusHandling } from "cruip-js-toolkit";
import Products from "./ProductsTableItem";
import FilterButton from "../../components/DropdownFilter";
import { Pagination } from "antd";
import axios from "axios";

function BestProductsTable({
  selectedItems,
  handleMultiplePhoto,
  updatePhoto,
}) {
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [products, setProducts] = useState([]);
  const [fp, setFp] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    axios
      .get(`https://adminapi.youjifresh.com/products?bestseller=true`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setProducts(response.data);
        console.log(products);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // useEffect(() => {

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [products,fp]);

  useEffect(() => {
    focusHandling("outline");
  }, [products]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (products && fp.length === 0) {
      setIsCheck(products.map((li) => li.id.toString()));
    } else if (fp) {
      setIsCheck(fp.map((li) => li.id.toString()));
    }
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  const getData = (val) => {
    console.log(val);
    setProducts();
    setFp(val);
    console.log(fp);
  };
  const get = (products) => {
    setFp([]);
    setProducts(products);
  };

  const handleselect = (e) => {
    console.log(e.target.value, "select");
    if (e.target.value === "Name") {
      axios
        .get(
          `https://adminapi.youjifresh.com/products?_sort=name&_limit=-1&bestseller=true`,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          setProducts(response.data);
          console.log(products);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (e.target.value === "Price") {
      axios
        .get(
          `https://adminapi.youjifresh.com/products?_sort=price&_limit=-1&bestseller=true`,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          setProducts(response.data);
          console.log(products);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (e.target.value === -1) {
      axios
        .get(
          `https://adminapi.youjifresh.com/products?_sort=created_at:DESC&_limit=-1&bestseller=true`,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          setProducts(response.data);
          console.log(products);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      const slug = e.target.value;
      axios
        .get(
          `https://adminapi.youjifresh.com/products?_category.slug=${slug}&_limit=-1&bestseller=true`,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          setProducts(response.data);
          console.log(products);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a style={{ userSelect: "none" }}>Previous</a>;
    }
    if (type === "next") {
      return <a style={{ userSelect: "none" }}>Next</a>;
    }
    return originalElement;
  }

  const [productsPerPage, setProductsPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexofFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexofFirstProduct,
    indexOfLastProduct
  );

  const paginate = (currentPage, productsPerPage) => {
    setCurrentPage(currentPage);
  };
  function onShowSizeChange(current, pageSize) {
    setProductsPerPage(pageSize);
  }

  const fil = [];

  if (searchTerm.length > 2) {
    products?.map((product) => {
      if (product.Name.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
        fil.push(product);
      }
    });
    console.log(fil);
  }

  return (
    <>
      <div className=" bg-white shadow-lg rounded-sm border border-gray-200 relative">
        <header className="px-5 py-4 ">
          <h2 className="font-semibold text-gray-800">
            Best Sellers{" "}
            <span className="text-gray-400 font-medium p-4">
              {products && products.length}
            </span>
            <input
              className="h-10 m-15 w-md-screen pr-6 pl-5 rounded z-0 contain focus:shadow focus:outline-none overflow-hidden"
              type="text"
              placeholder="Search Name..."
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />{" "}
            {/* <FilterButton sendData={getData} clearData={get} align="left" products={products} setProducts={setProducts}/> */}
            <span className="px-5 py-4 ">Sort :</span>
            <select onChange={handleselect}>
              <option value={-1}>Latest</option>
              <option value="Price">Price</option>
              <option value="Name">Name</option>
              <optgroup label="Category">
                <option value="beef">Beef</option>
                <option value="bones">Bones</option>

                <option value="chicken">Chicken</option>
                <option value="duck">Duck</option>
                <option value="eggs">Eggs</option>
                <option value="fish">Fish</option>
                <option value="flours-and-sauces">Flours & Sauces</option>
                <option value="fishball">Fish Ball</option>
                <option value="fresh-mutton">Mutton</option>
                <option value="shabu.">Frozen Food</option>
                <option value="good">Good for Confinement</option>
                <option value="noodles">Noodles</option>
                <option value="pork">Pork</option>
                <option value="trotters">Trotters</option>
              </optgroup>
            </select>
          </h2>
        </header>

        <div>
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="table-auto w-full divide-y divide-gray-200">
              {/* Table header */}
              <thead className="text-xs uppercase text-gray-500 bg-gray-50 border-t border-gray-200">
                <tr>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                    <div className="flex items-center">
                      <label className="inline-flex">
                        <span className="sr-only">Select all</span>
                        <input
                          className="form-checkbox"
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </label>
                    </div>
                  </th>
                  <th
                    className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                    width="10%"
                  >
                    <div className="font-semibold text-left">Id</div>
                  </th>
                  <th
                    className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                    width="30%"
                  >
                    <div className="font-semibold text-left">Name</div>
                  </th>
                  <th
                    className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                    width="15%"
                  >
                    <div className="font-semibold text-left">Description</div>
                  </th>
                  <th
                    className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                    width="15%"
                  >
                    <div className="font-semibold text-left">Price</div>
                  </th>

                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Category</div>
                  </th>

                  {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Photo</div>
                </th> */}
                </tr>
              </thead>
              {/* Table body */}
              {searchTerm
                ? fil.map((product) => {
                    return (
                      <Products
                        key={product.id}
                        handleClick={handleClick}
                        isChecked={isCheck.includes(product.id.toString())}
                        name={product.Name}
                        price={product.price}
                        id={product.id}
                        description={product.description}
                        category={
                          product.category !== null ? product.category.name : ""
                        }
                        stock={product.stocks}
                        photo={product.photo}
                        slug={product.slug}
                        meta_title={product.meta_title}
                        meta_description={product.meta_description}
                        shipping={product.shipping}
                        order_cutoff_time={product.order_cut_off_time}
                        onsale={product.OnSale}
                        storage={product.storage}
                        catid={
                          product.category !== null ? product.category.id : null
                        }
                        handleMultiplePhoto={handleMultiplePhoto}
                        updatePhoto={updatePhoto}
                        bestseller={product.bestseller}
                      />
                    );
                  })
                : currentProducts.map((product) => {
                    return (
                      <Products
                        key={product.id}
                        handleClick={handleClick}
                        isChecked={isCheck.includes(product.id.toString())}
                        name={product.Name}
                        price={product.price}
                        id={product.id}
                        description={product.description}
                        category={
                          product.category !== null ? product.category.name : ""
                        }
                        stock={product.stocks}
                        photo={product.photo}
                        slug={product.slug}
                        meta_title={product.meta_title}
                        meta_description={product.meta_description}
                        shipping={product.shipping}
                        order_cutoff_time={product.order_cut_off_time}
                        onsale={product.OnSale}
                        storage={product.storage}
                        catid={
                          product.category !== null ? product.category.id : null
                        }
                        handleMultiplePhoto={handleMultiplePhoto}
                        updatePhoto={updatePhoto}
                        bestseller={product.bestseller}
                      />
                    );
                  })}
            </table>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-4">
        <Pagination
          current={currentPage}
          showSizeChanger
          itemRender={itemRender}
          total={products.length}
          pageSize={productsPerPage}
          onChange={paginate}
          onShowSizeChange={onShowSizeChange}
        />
      </div>
    </>
  );
}

export default BestProductsTable;
