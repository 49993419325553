import React, { useState, useEffect } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import { message } from "antd";

function Points() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [points, setPoints] = useState(0);
  const [def, setDef] = useState(0);

  const update = (e) => {
    e.preventDefault();
    var axios = require("axios");
    var data = JSON.stringify({
      points_per_dollar: points,
    });

    var config = {
      method: "put",
      url: "https://adminapi.youjifresh.com/points",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        message.success("Points updated");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(function (error) {
        console.log(error);
        message.error(error.response.data.message);
      });
  };

  useEffect(() => {
    var axios = require("axios");

    var config = {
      method: "put",
      url: "https://adminapi.youjifresh.com/points",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setDef(response.data.points_per_dollar);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Points
                </h1>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-6">Points_Per_Dollar</div>
            <br></br>
            <div className="flex-auto">
              <form onSubmit={update}>
                <input
                  type="number"
                  style={{ marginRight: "40px" }}
                  value={def}
                  onChange={(e) => {
                    setDef();
                    setPoints(e.target.value);
                    console.log(e.target.value);
                  }}
                ></input>
                <button
                  className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                  type="submit"
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Points;
