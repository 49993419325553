import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

console.log(moment.getDate);
const Sales = () => {
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState();
  const [created_at, setCreated_at] = useState();
  const [today, setToday] = useState();
  const qs = require("qs");

  console.log();

  const date = new Date();
  const now = date.toISOString().slice(0, 10);
  console.log(now);
  // const query = qs.stringify(
  //   {
  //     filters: {
  //       created_at: {
  //         $eq: "John",
  //       },
  //     },
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );

  useEffect(() => {
    axios
      .get(`https://adminapi.youjifresh.com/allorders?_sort=created_at:DESC`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        // console.log(response.data);
        setOrders(response.data);
        console.log(orders);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://adminapi.youjifresh.com/orders/count`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        setCount(response.data);
        // console.log(count);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const NewOrdesr = () => {
    var todays_order = 0;
    orders.map((order) => {
      if (order) {
        if (now == order.created_at.slice(0, 10)) {
          console.log("yes");
          todays_order++;
          console.log(todays_order);
        }
        setToday(todays_order);
      }
    });
    return null;
  };

  return (
    <div>
      <div class="bg-green-300 text-lg bold border-a-600 border-b p-4 m-4 rounded">
        <div>Total Sale count : {count}</div>
      </div>
      <NewOrdesr />
      <div class="bg-green-300 text-lg bold border-a-600 border-b p-4 m-4 rounded">
        <div>Today's Sale count : {today}</div>
      </div>
    </div>
  );
};

export default Sales;
