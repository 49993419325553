import React, { useState, useEffect } from "react";

import Sidebar from "../Sidebar";
import Header from "../Header";
import axios from "axios";
import ProductImageListing from "../../components/ProductImageListing";
import _ from "loadsh";
import { useHistory } from "react-router-dom";

import { message, Carousel } from "antd";
import AddHeader from "../AddHeader.js";
import ProductHeader from "./ProductHeaders";

function UpdateProduct(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  console.log(props);
  const history = useHistory();

  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [cat, setCat] = useState([]);
  const [update, setUpdate] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [change, setChange] = useState();
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState();
  const [stock, setStock] = useState();
  const [slug, setSlug] = useState();
  const [meta_title, setMeta_title] = useState();
  const [meta_description, setMeta_description] = useState();
  const [shipping, setShipping] = useState();
  const [order_cutoff_time, setOrder_cutoff_time] = useState();
  const [storage, setStorage] = useState();
  const [onsale, setOnsale] = useState();
  const [catid, setCatid] = useState();
  const [url, seturl] = useState();

  const [uname, setUName] = useState("");
  const [uprice, setUPrice] = useState("");
  const [ucategory, setUCategory] = useState("");
  const [udescription, setUDescription] = useState();
  const [ustock, setUStock] = useState();
  const [uslug, setUSlug] = useState();
  const [umeta_title, setUMeta_title] = useState();
  const [umeta_description, setUMeta_description] = useState();
  const [ushipping, setUShipping] = useState();
  const [uorder_cutoff_time, setUOrder_cutoff_time] = useState();
  const [ustorage, setUStorage] = useState();
  const [uonsale, setUOnsale] = useState();
  const [ucatid, setUCatid] = useState();

  const [Image, setImage] = useState([]);
  const [UImage, setUImage] = useState([]);
  const [PhotoId, setPhotoId] = useState("");
  const [PhotoModal, setPhotoModal] = useState(false);
  const [Url, setUrl] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [uphoto, setUphoto] = useState([]);
  const [photo, setPhoto] = useState([]);
  const [id, setId] = useState();
  const [bestSeller, setBestSeller] = useState();
  const [UBestSeller, setUBestSeller] = useState();

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    setName(props.location.state.name);
    setCategory(props.location.state.category);
    setPrice(props.location.state.price);
    setDescription(props.location.state.description);
    setStock(props.location.state.stock);
    setSlug(props.location.state.slug);
    setMeta_title(props.location.state.meta_title);
    setMeta_description(props.location.state.meta_description);
    setShipping(props.location.state.shipping);
    setOrder_cutoff_time(props.location.state.order_cutoff_time);
    setStorage(props.location.state.storage);
    setOnsale(props.location.state.onsale);
    setCatid(props.location.state.catid);
    // seturl(props.location.state.photo.map((p) => p.url));
    setPhoto(props.location.state.photo);
    setId(props.location.state.id);
    setBestSeller(props.location.state.bestseller);
    console.log(id);

    setUName(props.location.state.pname);
    setUCategory(props.location.state.category);
    setUPrice(props.location.state.price);
    setUDescription(props.location.state.description);
    setUStock(props.location.state.stock);
    setUSlug(props.location.state.slug);
    setUMeta_title(props.location.state.meta_title);
    setUMeta_description(props.location.state.meta_description);
    setUShipping(props.location.state.shipping);
    setUOrder_cutoff_time(props.location.state.order_cutoff_time);
    setUStorage(props.location.state.storage);
    setUOnsale(props.location.state.onsale);
    setUCatid(props.location.state.catid);
    setUphoto(props.location.state.photo);
    setUBestSeller(props.location.state.bestseller);
  }, []);

  useEffect(() => {
    axios
      .get(`https://adminapi.youjifresh.com/categories`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setCat(response.data);
        console.log(cat);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const UpdateProduct = (id) => {
    console.log(id);
    return (e) => {
      e.preventDefault();

      if (uname === "" || udescription === "" || uprice === "") {
        message.error("Required field/s empty");
      } else {
        var axios = require("axios");

        var data = JSON.stringify({
          Name: uname ? uname : name,
          price: uprice ? uprice : price,
          description: udescription ? udescription : description,
          category: ucatid || ucatid === "" ? ucatid : catid,
          stocks: ustock ? ustock : stock ? stock : null,
          slug: uslug || uslug === "" ? uslug : slug,
          meta_title:
            umeta_title || umeta_title === "" ? umeta_title : meta_title,
          meta_description:
            umeta_description || umeta_description === ""
              ? umeta_description
              : meta_description,
          shipping: ushipping || ushipping === "" ? ushipping : shipping,
          order_cut_off_time:
            uorder_cutoff_time || uorder_cutoff_time === ""
              ? uorder_cutoff_time
              : order_cutoff_time,
          storage: ustorage || storage === "" ? ustorage : storage,
          OnSale: uonsale ? uonsale : onsale,
          photo: uphoto ? uphoto : Image,
          bestseller: UBestSeller ? UBestSeller : bestSeller,
        });

        var config = {
          method: "put",
          url: `https://adminapi.youjifresh.com/products/${id}`,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            message.success("Product Updated");
            setChange(false);
            setShowModal(false);
          })
          .catch(function (error) {
            console.log(error);
            message.error(error.response.data.message);
          });
      }
      setUpdate(false);
    };
  };

  const cancel = () => {
    setShowModal(false);
    setName(props.location.state.name);
    setCategory(props.location.state.category);
    setDescription(props.location.state.description);
    setPrice(props.location.state.price);
    setStock(props.location.state.stock);
    setSlug(props.location.state.slug);
    setMeta_title(props.location.state.meta_title);
    setMeta_description(props.location.state.meta_description);
    setShipping(props.location.state.shipping);
    setOrder_cutoff_time(props.location.state.order_cutoff_time);
    setStorage(props.location.state.storage);
    setOnsale(props.location.state.onsale);
    setBestSeller(props.location.state.bestSeller);
    // seturl(props.photo.map((p) => p.url));
    setPhoto(props.location.state.photo);
    setUphoto(props.location.state.photo);
    setUName(props.location.state.name);
    setUCategory(props.location.state.category);
    setUDescription(props.location.state.description);
    setUPrice(props.location.state.price);
    setUStock(props.location.state.stock);
    setUSlug(props.location.state.slug);
    setUMeta_title(props.location.state.meta_title);
    setUMeta_description(props.location.state.meta_description);
    setUShipping(props.location.state.shipping);
    setUOrder_cutoff_time(props.location.state.order_cutoff_time);
    setUStorage(props.location.state.storage);
    setUOnsale(props.location.state.onsale);
    setUBestSeller(props.location.state.bestSeller);
    if (change === true) {
      // const text =
      window.alert("All the changes will be discarded");
      // if(text===true){
      setUName(props.location.state.name);
      setUCategory(props.location.state.category);
      setUDescription(props.location.state.description);
      setUPrice(props.location.state.price);
      setUStock(props.location.state.stock);
      setUSlug(props.location.state.slug);
      setUMeta_title(props.location.state.meta_title);
      setUMeta_description(props.location.state.meta_description);
      setUShipping(props.location.state.shipping);
      setUOrder_cutoff_time(props.location.state.order_cutoff_time);
      setUStorage(props.location.state.storage);
      setUOnsale(props.location.state.onsale);
      setUCatid(props.location.state.catid);
      setPhoto(props.location.state.photo);
      setUphoto();
      setShowModal(false);
      setUrl();
      setUBestSeller(props.location.state.bestSeller);
      message.warning("Changes discarded");
      // }
      // else{
      //   setShowModal(true);
      //   // setName(uname?uname:name)
      //   // setCategory(ucategory?ucategory:category);
      //   // setDescription(udescription?udescription:description);
      //   // setPrice(uprice?uprice:price);
      //   // setStock(ustock?ustock:stock)
      //   // setSlug(uslug?uslug:slug)
      //   // setMeta_title(umeta_title?umeta_title:meta_title)
      //   // setMeta_description(umeta_description?umeta_description:meta_description)
      //   // setShipping(ushipping?ushipping:shipping)
      //   // setOrder_cutoff_time(uorder_cutoff_time?uorder_cutoff_time:order_cutoff_time)
      //   // setStorage(ustorage?ustorage:storage)
      //   // setOnsale(uonsale?uonsale:onsale)
      // }
      setChange(false);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    setUpdate(false);
  };

  const mouseover = () => {
    document.getElementById(`${props.name}`).style.backgroundColor = "#F0F0F0";
  };
  const mouseout = () => {
    document.getElementById(`${props.name}`).style.backgroundColor = "white";
  };

  const handleMultiplePhoto = (asset) => {
    console.log(asset);
    for (let i = 0; i < 10; i++) {
      if (photo[i] == null) {
        photo[i] = asset;
        console.log(asset);
        break;
      }
    }
    console.log("photo", photo);
    setUphoto(photo);
  };
  const handleChange = () => {
    console.log("click change");
  };

  const handlePhotoUpdate = (e) => {
    e.preventDefault();
    setPhotoModal(true);
    // console.log(props.updatePhoto);
    // setPhoto();
    // setUphoto(props.updatePhoto);
    console.log("pti", uphoto);
    // setImage(props)
    setUpdate(true);
    // seturl();
    // setPhotoModal(true);
    setChange(true);
  };

  const contentStyle = {
    height: "100px",
    color: "#fff",
    lineHeight: "100px",
    textAlign: "center",
    background: "#364d79",
  };

  const clearPhoto = () => {
    setPhoto([]);
  };

  // <button onClick={()=>{window.location.reload()}}>Back</button>
  return (
    <>
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}

          <ProductHeader
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full  mx-auto">
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                {/* <div className="justify-center items-center flex overflow-x-hidden  overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl ">  */}

                {/*content*/}
                {/* <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-screen"> */}
                {/*header*/}
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                    Update products
                  </h1>
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                  {/* Delete button */}

                  {/* <DateSelect /> */}
                  {/* Filter button */}

                  {/* Add customer button */}
                </div>
              </div>

              {/*body*/}
              <div className="relative  flex-auto ">
                <form id="form_add_prod">
                  <div class="flex flex-wrap -mx-2 mb-2">
                    <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_name"
                      >
                        NAME
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_name"
                        type="text"
                        value={uname ? uname : name}
                        onChange={(e) => {
                          setUpdate(true);
                          if ((name || uname) !== e.target.value) {
                            setChange(true);
                          }

                          setName();
                          setUName(e.target.value);
                          console.log(e.target.value);
                        }}
                        placeholder="product_name"
                      />
                    </div>

                    <div class="w-full md:w-2/3 px-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_description"
                      >
                        DESCRIPTION
                      </label>
                      <textarea
                        rows={4}
                        class="appearance-none block text-clip resize-y overflow-hidden w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_description"
                        type="textarea"
                        value={udescription ? udescription : description}
                        onChange={(e) => {
                          setUpdate(true);
                          if (
                            (description || udescription) !== e.target.value
                          ) {
                            setChange(true);
                          }
                          setDescription();
                          setUDescription(e.target.value);
                          console.log(e.target.value);
                        }}
                        placeholder="description of the product"
                      />
                    </div>

                    <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_price"
                      >
                        PRICE ($)
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 touch-none text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_price"
                        type="number"
                        step="0.01"
                        onScroll={document.activeElement.blur()}
                        value={uprice ? uprice : price}
                        onChange={(e) => {
                          setUpdate(true);
                          if ((price || uprice) !== e.target.value) {
                            setChange(true);
                          }
                          setPrice();
                          setUPrice(e.target.value);
                          console.log(e.target.value);
                        }}
                        placeholder="123 $"
                        required
                      />
                    </div>

                    <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_category"
                      >
                        CATEGORY
                      </label>
                      <div class="relative">
                        <select
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_category"
                          value={ucatid ? ucatid : catid}
                          onChange={(e) => {
                            setUpdate(true);
                            if ((catid || ucatid) !== e.target.value) {
                              setChange(true);
                            }
                            setCatid();
                            setUCatid(e.target.value);
                            console.log(e.target.value);
                          }}
                        >
                          {cat.map((c) => (
                            <>
                              <option value={c.id}>{c.name}</option>
                            </>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0 ">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_image"
                      >
                        PHOTO
                      </label>
                      <div class="relative">
                        <Carousel autoplay>
                          {photo
                            ? photo.map((p) => (
                                <div>
                                  <h3 style={contentStyle}>
                                    <img
                                      src={p.formats.thumbnail.url}
                                      style={{ objectFit: "contain" }}
                                      height="100px"
                                      alt="ProductImage"
                                    />
                                  </h3>
                                </div>
                              ))
                            : uphoto.map((p) => (
                                <div>
                                  <h3 style={contentStyle}>
                                    <img
                                      src={p.formats.thumbnail.url}
                                      style={{ objectFit: "contain" }}
                                      height="100px"
                                      alt="ProductImage"
                                    />
                                  </h3>
                                </div>
                              ))}
                        </Carousel>
                      </div>
                      <button
                        id="form__product_image"
                        className=" btn bg-yellow-500 w-full  hover:bg-yellow-600 text-white mt-2"
                        onClick={handlePhotoUpdate}
                      >
                        <span className="hidden xs:block">Change</span>
                      </button>

                      {/* <button
                        className=" btn bg-yellow-500 w-full  hover:bg-yellow-600 text-white mt-2"
                        onClick={clearPhoto}
                      >
                        <span className="hidden xs:block">Clear</span>
                      </button> */}
                    </div>

                    <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_stock"
                      >
                        STOCK
                      </label>
                      <div class="relative">
                        <input
                          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_stock"
                          type="number"
                          value={ustock ? ustock : stock}
                          onChange={(e) => {
                            setUpdate(true);
                            if ((stock || ustock) !== e.target.value) {
                              setChange(true);
                            }
                            setStock();
                            setUStock(e.target.value);
                            console.log(e.target.value);
                          }}
                          placeholder="stock"
                        />
                      </div>
                    </div>

                    <div class="w-full md:w-2/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_slug"
                      >
                        Slug
                      </label>
                      <div class="relative">
                        <input
                          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_slug"
                          type="text"
                          value={uslug ? uslug : slug}
                          onChange={(e) => {
                            setUpdate(true);
                            if ((slug || uslug) !== e.target.value) {
                              setChange(true);
                            }
                            setSlug();
                            setUSlug(e.target.value);
                            console.log(e.target.value);
                          }}
                          placeholder="slug"
                        />
                      </div>
                    </div>

                    <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_onsale"
                      >
                        Onsale [Offer]
                      </label>
                      <div class="relative">
                        <select
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_onsale"
                          value={uonsale ? uonsale : onsale}
                          onChange={(e) => {
                            setUpdate(true);
                            if ((onsale || uonsale) !== e.target.value) {
                              setChange(true);
                            }
                            setOnsale();
                            setUOnsale(e.target.value);
                            console.log(e.target.value);
                          }}
                        >
                          <option value={onsale} selected>
                            {onsale === true
                              ? "True"
                              : onsale === false
                              ? "False"
                              : "Select"}
                          </option>
                          <option value={true} hidden={onsale === true}>
                            True
                          </option>
                          <option value={false} hidden={onsale === false}>
                            False
                          </option>
                        </select>
                      </div>
                    </div>

                    {/* <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_shipping"
                      >
                        Shipping
                      </label>
                      <div class="relative">
                        <textarea
                          rows={2}
                          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_shipping"
                          type="text"
                          value={shipping ? shipping : ushipping}
                          onChange={(e) => {
                            setUpdate(true);
                            if ((shipping || ushipping) !== e.target.value) {
                              setChange(true);
                            }
                            setShipping();
                            setUShipping(e.target.value);
                            console.log(e.target.value);
                          }}
                          placeholder="shipping"
                        />
                      </div>
                    </div> */}

                    <div class="w-full md:w-4/6 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_meta_title"
                      >
                        Meta Title
                      </label>
                      <div class="relative">
                        <textarea
                          rows={2}
                          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_meta_title"
                          type="text"
                          value={umeta_title ? umeta_title : meta_title}
                          onChange={(e) => {
                            setUpdate(true);
                            if (
                              (meta_title || umeta_title) !== e.target.value
                            ) {
                              setChange(true);
                            }
                            setMeta_title();
                            setUMeta_title(e.target.value);
                            console.log(e.target.value);
                          }}
                          placeholder="meta title"
                        />
                      </div>
                    </div>

                    <div class="w-full md:w-4/6 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_meta_description"
                      >
                        Meta Description
                      </label>
                      <div class="relative">
                        <textarea
                          rows={3}
                          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_meta_description"
                          type="text"
                          value={
                            umeta_description
                              ? umeta_description
                              : meta_description
                          }
                          onChange={(e) => {
                            setUpdate(true);
                            if (
                              (meta_description || umeta_description) !==
                              e.target.value
                            ) {
                              setChange(true);
                            }
                            setMeta_description();
                            setUMeta_description(e.target.value);
                            console.log(e.target.value);
                          }}
                          placeholder="meta description"
                        />
                      </div>
                    </div>
                    <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_bestSeller"
                      >
                        Best Seller
                      </label>
                      <div class="relative">
                        <select
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_bestSeller"
                          value={UBestSeller ? UBestSeller : bestSeller}
                          onChange={(e) => {
                            setUpdate(true);
                            if (
                              (bestSeller || UBestSeller) !== e.target.value
                            ) {
                              setChange(true);
                            }
                            setBestSeller();
                            setUBestSeller(e.target.value);
                            console.log(e.target.value);
                          }}
                        >
                          <option value={onsale} selected>
                            {onsale === true
                              ? "True"
                              : onsale === false
                              ? "False"
                              : "Select"}
                          </option>
                          <option value={true} hidden={onsale === true}>
                            True
                          </option>
                          <option value={false} hidden={onsale === false}>
                            False
                          </option>
                        </select>
                      </div>
                    </div>

                    {/* <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_o"
                      >
                        Order Cut Off Time
                      </label>
                      <div class="relative">
                        <textarea
                          rows={2}
                          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_o"
                          type="text"
                          value={
                            order_cutoff_time
                              ? order_cutoff_time
                              : uorder_cutoff_time
                          }
                          onChange={(e) => {
                            setUpdate(true);
                            if (
                              (order_cutoff_time || uorder_cutoff_time) !==
                              e.target.value
                            ) {
                              setChange(true);
                            }
                            setOrder_cutoff_time();
                            setUOrder_cutoff_time(e.target.value);
                            console.log(e.target.value);
                          }}
                          placeholder="order cutoff time"
                        />
                      </div>
                    </div> */}

                    {/* <div class="w-full md:w-2/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_storage"
                      >
                        Storage
                      </label>
                      <div class="relative">
                        <textarea
                          rows={2}
                          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_storage"
                          type="text"
                          value={storage ? storage : ustorage}
                          onChange={(e) => {
                            setUpdate(true);
                            if ((storage || ustorage) !== e.target.value) {
                              setChange(true);
                            }
                            setStorage();
                            setUStorage(e.target.value);
                            console.log(e.target.value);
                          }}
                          placeholder="storage"
                        />
                      </div>
                    </div> */}
                  </div>
                </form>
              </div>
              {/*footer*/}
              <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
                <div className="justify-items-end ">
                  <button
                    className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
                    onClick={cancel}
                  >
                    <span className="hidden xs:block ">Cancel</span>
                  </button>
                  {update ? (
                    <button
                      className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                      onClick={UpdateProduct(props.location.state.id)}
                    >
                      <span className="hidden xs:block">Update</span>
                    </button>
                  ) : (
                    <button
                      className="btn bg-yellow-500 opacity-50 text-white"
                      disabled
                    >
                      <span className="hidden xs:block">Update</span>
                    </button>
                  )}

                  {/* modal ends  */}
                </div>
              </div>
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}

              {/* Pagination */}
            </div>
          </main>
          {PhotoModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-6xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-screen">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                      <h3 className="text-3xl font-semibold">Photo Select</h3>
                      <button
                        type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-toggle="defaultModal"
                        onClick={() => setPhotoModal(false)}
                      >
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto overflow-y-scroll">
                      <ProductImageListing
                        selectedItems={handleSelectedItems}
                        setPhotoId={setPhotoId}
                        setPhotoModal={setPhotoModal}
                        setUrl={setUrl}
                        setImage={setImage}
                        handleMultiplePhoto={handleMultiplePhoto}
                      />
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid  border-blueGray-200 rounded-b">
                      <button
                        className="text-red-500  font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          setPhotoModal(false);
                          // seturl(props.photo.map((p) => p.url));
                        }}
                      >
                        Choose
                      </button>
                      <button
                        className="text-red-500  font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          setPhotoId();
                          setPhoto([]);
                          // seturl(props.photo.map((p) => p.url));
                        }}
                      >
                        clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default UpdateProduct;
