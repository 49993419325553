import React,{useState,useEffect} from 'react'
import Sidebar from "../Sidebar";
import Header from "../Header";
import { message } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { DatePicker } from 'antd';
import moment from 'moment';
import CouponHeader from './CouponHeader';

function UpdateCoupon(props) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const history = useHistory()
    const [showModal,setShowModal]=useState(false)
    const [update,setUpdate]=useState(false)
    const [change,setChange]=useState()
  
    const [percentage,setPercentage]=useState()
    const [active,setActive]=useState()
    const [coupon_code,setCoupon_code]=useState()
    const [value,setValue]=useState()
    const [maxValue,setMaxValue]=useState()
    const [expiry_date,setExpiry_date]=useState()
    const [id,setId]=useState()
  
    const [upercentage,setUPercentage]=useState()
    const [uactive,setUActive]=useState()
    const [ucoupon_code,setUCoupon_code]=useState()
    const [uvalue,setUValue]=useState()
    const [umaxValue,setUMaxValue]=useState()
    const [uexpiry_date,setUExpiry_date]=useState()
    const [minValue,setMinValue]=useState()
    const [usage,setUsage]=useState()
    const [uminValue,setUMinValue]=useState()
    const [uusage,setUUsage]=useState()
    useEffect(() => {
        setPercentage(props.location.state.percentage)
        setActive(props.location.state.active)
        setCoupon_code(props.location.state.coupon_code)
        setValue(props.location.state.value)
        setMaxValue(props.location.state.maxValue)
        setExpiry_date(props.location.state.expiry_date)
        setId(props.location.state.id)
        setMinValue(props.location.state.minValue)
        setUsage(props.location.state.usage)
        setUMinValue(props.location.state.minValue)
        setUUsage(props.location.state.usage)
        setUPercentage(props.location.state.percentage)
        setUActive(props.location.state.active)
        setUCoupon_code(props.location.state.coupon_code)
        setUValue(props.location.state.value)
        setUMaxValue(props.location.state.maxValue)
        setUExpiry_date(props.location.state.expiry_date)
       console.log(props,"Update Props")
      }, [])
    
    
      const mouseover = () => {
        document.getElementById(`${props.coupon_code}`).style.backgroundColor='#F0F0F0'
      }
      const mouseout = () => {
        document.getElementById(`${props.coupon_code}`).style.backgroundColor='white'
      }
    
      const UpdateCoupon = (id) => {
        return e => {
          e.preventDefault()
          if((upercentage===undefined||upercentage===-1)||(uactive===undefined||uactive===-1)||ucoupon_code===''||uvalue===''||umaxValue===''||(uexpiry_date===''||uexpiry_date===null)){
            message.error('Required field/s empty')
          }
      else{
          var axios = require("axios");
      
          var data = JSON.stringify({
            Percentage:upercentage?upercentage:percentage,
            Active:uactive?uactive:active,
            coupon_code:ucoupon_code?ucoupon_code:coupon_code,
            Value:uvalue?uvalue:value,
            Max_value:umaxValue?umaxValue:maxValue,
            Expiry_Date:uexpiry_date?uexpiry_date:expiry_date,
            min_cart_value: uminValue?uminValue:minValue,
            usage: uusage?uusage:usage
          });
      
          var config = {
            method: "put",
            url: `https://adminapi.youjifresh.com/coupons/${id}`,
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
              "Content-Type": "application/json",
            },
            data: data,
          };
      
          axios(config)
            .then(function (response) {
              console.log(JSON.stringify(response.data));
              message.success("Coupon Updated");
              setChange(false)
              setShowModal(false)
                history.push('/coupons')
             
            })
            .catch(function (error) {
              console.log(error);
              message.error(error.response.data.message);
            });
          }
          setUpdate(false)
        }
      }
    
      const cancel = () => {
        setShowModal(false)
        setPercentage(props.location.state.percentage)
        setActive(props.location.state.active)
        setCoupon_code(props.location.state.coupon_code)
        setValue(props.location.state.value)
        setMaxValue(props.location.state.maxValue)
        setExpiry_date(props.location.state.expiry_date)
        setId(props.location.state.id)
    
        setUPercentage(props.location.state.percentage)
        setUActive(props.location.state.active)
        setUCoupon_code(props.location.state.coupon_code)
        setUValue(props.location.state.value)
        setUMaxValue(props.location.state.maxValue)
        setUExpiry_date(props.location.state.expiry_date)
    
        if(change===true){
    
       
    
    
          window.alert("All the changes will be discarded")
          setUPercentage(props.location.state.percentage)
          setUActive(props.location.state.active)
          setUCoupon_code(props.location.state.coupon_code)
          setUValue(props.location.state.value)
          setUMaxValue(props.location.state.maxValue)
          setUExpiry_date(props.location.state.expiry_date)
    
            setShowModal(false)
            message.warning('Changes discarded')
    
          setChange(false)
        }
        setUpdate(false)
      }
    
    return (
       <>
          <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <CouponHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl p-6 md:text-3xl text-gray-800 font-bold">
                  Update Coupon
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Delete button */}
               
                {/* Dropdown */}
                {/* <DateSelect /> */}
                {/* Filter button */}

                {/* Add customer button */}
                
              </div>
            </div>
         

            {/* Table */}
            <div className="relative px-4 flex-auto">
                        <form id="form_add_Coupon">
                          <div class="flex flex-wrap -mx-2 mb-2">
                            <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_percentage"
                              >
                                PERCENTAGE
                              </label>
                              <div className='relative'>
                            <select
                                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="form__coupon_percentage"
                                  value={percentage?percentage:upercentage}
                                  onChange={(e) => {
                                    setUpdate(true)
                                    if((percentage || upercentage)!==e.target.value){
                                      setChange(true)
                                    }
                                    setPercentage();
                                    setUPercentage(e.target.value)
                                    console.log(e.target.value);
                                  }}
                                >
                                  <option value={percentage} selected>{percentage===true?'True':percentage===false?'False':'Select'}</option>
                                  <option value={true} hidden={percentage===true}>True</option>
                                  <option value={false} hidden={percentage===false}>False</option> 
                                  
                                  
                                </select>
                                </div>
                            </div>

                            <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_active"
                              >
                                Active
                              </label>
                              <div className='relative'>
                            <select
                                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="form__coupon_active"
                                  value={active?active:uactive}
                                  onChange={(e) => {
                                    setUpdate(true)
                                    if((active || uactive)!==e.target.value){
                                      setChange(true)
                                    }
                                    setActive();
                                    setUActive(e.target.value)
                                    console.log(e.target.value);
                                  }}
                                >
                                  <option value={active} selected>{active===true?'True':active===false?'False':'Select'}</option>
                                  <option value={true} hidden={active===true}>True</option>
                                  <option value={false} hidden={active===false}>False</option>         
                                  
                                  
                                </select>
                                </div>
                            </div>

                            <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_code"
                              >
                                Coupon Code
                              </label>
                              {console.log(coupon_code)}
                              <input
                                class="appearance-none block text-clip resize-y overflow-hidden w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__coupon_code"
                                type="text"
                                value={coupon_code?coupon_code:ucoupon_code}
                                onChange={(e) => {
                                  setUpdate(true)
                                  if((coupon_code || ucoupon_code)!==e.target.value){
                                    setChange(true)
                                  }
                                  setCoupon_code();
                                  setUCoupon_code(e.target.value)
                                  console.log(e.target.value);
                                }}
                                placeholder="Coupon Code"
                              />
                            </div>

                            <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_value"
                              >
                                Value
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__coupon_value"
                                type="number"
                                value={value?value:uvalue}
                                onChange={(e) => {
                                  setUpdate(true)
                                  if((value || uvalue)!==e.target.value){
                                    setChange(true)
                                  }
                                  setValue();
                                  setUValue(e.target.value)
                                  console.log(e.target.value);
                                }}
                                placeholder="ex. 10"
                              />
                            </div>
                            <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_mvalue"
                              >
                                Minimum Cart VALUE
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__coupon_mvalue"
                                type="number"
                                value={minValue ? minValue : uminValue}
                                onChange={(e) => {
                                  setUpdate(true)
                                  if((minValue || uminValue)!==e.target.value){
                                    setChange(true)
                                  }
                                  setMinValue();
                                  setUMinValue(e.target.value)
                                }}
                                placeholder="ex. 10"
                              />
                            </div>
                            <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_mvalue"
                              >
                                USAGE/USER
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__coupon_mvalue"
                                type="number"
                                value={usage ? usage : uusage}
                                onChange={(e) => {
                                  // setUsage(e.target.value);
                                  // console.log(e.target.value);
                                  setUpdate(true)
                                  if((usage || uusage)!==e.target.value){
                                    setChange(true)
                                  }
                                  setUsage();
                                  setUUsage(e.target.value)
                                }}
                                placeholder="ex. 10"
                              />
                            </div>

                            <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_mvalue"
                              >
                                MAX VALUE
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__coupon_mvalue"
                                type="number"
                                value={maxValue?maxValue:umaxValue}
                                onChange={(e) => {
                                  setUpdate(true)
                                  if((maxValue || umaxValue)!==e.target.value){
                                    setChange(true)
                                  }
                                  setMaxValue();
                                  setUMaxValue(e.target.value)
                                  console.log(e.target.value);
                                }}
                                placeholder="ex. 10"
                              />
                            </div>
                          

                            <div class="w-full md:w-1/2 p-2 mb-5 mt-2 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_exp_date"
                              >
                                Expiry Date
                              </label>
                              <div class="relative">
                              
                                <DatePicker
                                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="form__coupon_exp_date"
                                  isclearable
                                  value={expiry_date!==null?moment.utc(expiry_date):uexpiry_date!==null?moment.utc(uexpiry_date):null}
                                  onChange={(date) => {
                                    setUpdate(true)
                                    setChange(true)
                                    setExpiry_date(null);
                                    setUExpiry_date(date)
                                    console.log(date)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                     
                      <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
                        
                        <div className="justify-items-end ">
                          <button
                            className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
                            onClick={cancel}
                          >
                            <span className="hidden xs:block">Cancel</span>
                          </button>
                          {
                            update ? 
                            <button
                            className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                            onClick={UpdateCoupon(id)}
                          >
                            <span className="hidden xs:block">
                              Update 
                            </span>
                          </button>: <button
                            className="btn bg-yellow-500 opacity-50 text-white"
                            disabled
                          >
                            <span className="hidden xs:block">
                              Update 
                            </span>
                          </button>
                          }
                        </div>
                      </div>
           
            {/* Pagination */}
            
          </div>
        </main>
      </div>
    </div>
       </>
    )
}

export default UpdateCoupon
