import React, { useState, useEffect } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import PaginationClassic from "../components/PaginationClassic";
import ProductsTable from "../partials/products/ProductsTable";
import { message, Carousel } from "antd";
import ProductDelete from "../partials/actions/ProductDelete";
import axios from "axios";
import ProductImageListing from "../components/ProductImageListing";
import AddProducts from "./AddProducts";
import BestProductsTable from "../partials/products/BestSeller";

function Products() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [products, setProducts] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [stock, setStock] = useState();
  const [slug, setSlug] = useState();
  const [meta_title, setMeta_title] = useState();
  const [meta_description, setMeta_description] = useState();
  const [shipping, setShipping] = useState();
  const [order_cutoff_time, setOrder_cutoff_time] = useState();
  const [storage, setStorage] = useState();
  const [onsale, setOnsale] = useState();
  const [catid, setCatid] = useState();

  const [cat, setCat] = useState([]);

  const [Image, setImage] = useState([]);
  const [PhotoId, setPhotoId] = useState("");
  const [PhotoModal, setPhotoModal] = useState(false);
  const [Url, setUrl] = useState("");
  const [Photo, setPhoto] = useState([]);
  const [bestSeller, setBestSeller] = useState(0);
  const [viewAllButton, setViewAllButton] = useState(1);

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  // useEffect(()=>{
  //       setName('');
  //       setPrice('');
  //       setCategory('');
  //       setDescription('');
  //       setStock('')
  //       setSlug('')
  //       setMeta_title('')
  //       setMeta_description('')
  //       setShipping('')
  //       setOrder_cutoff_time('')
  //       setStorage('')
  //       setOnsale('')
  // },[])
  useEffect(() => {
    axios
      .get(`https://adminapi.youjifresh.com/categories`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setCat(response.data);
        console.log(cat);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var axios = require("axios");

    var config = {
      method: "get",
      url: `https://adminapi.youjifresh.com/products`,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setName(response.data.Name);
        setPrice(response.data.price);
        setCategory(response.data.category);
        setDescription(response.data.description);
        setStock(response.data.stock);
        setSlug(response.data.slug);
        setMeta_title(response.data.meta_title);
        setMeta_description(response.data.meta_description);
        setShipping(response.data.shipping);
        setOrder_cutoff_time(response.data.order_cut_off_time);
        setStorage(response.data.storage);
        setOnsale(response.data.OnSale);
        setCatid(response.data.catid);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const clearForm = () => {
    setName("");
    setPrice("");
    setCategory("");
    setDescription("");
    setStock("");
    setSlug("");
    setMeta_title("");
    setMeta_description("");
    setShipping("");
    setOrder_cutoff_time("");
    setStorage("");
    setOnsale("");
    setImage("");
  };

  useEffect(() => {
    axios
      .get("https://adminapi.youjifresh.com/products?_limit=-1", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        // console.log(response.data);
        setProducts(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // useEffect for assets

  // useEffect(() => {
  //   if (sessionStorage.getItem("youji_token")) {
  //     axios
  //       .get("https://adminapi.youjifresh.com/upload/files", {
  //         headers: {
  //           Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
  //         },
  //       })
  //       .then((res) => {
  //         console.log("rooooo", res.data);
  //         setAssets(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         message.error(err);
  //       });
  //   }
  // }, []);

  const handlePhotoModalChange = (e) => {
    e.preventDefault();
    setPhotoModal(true);
  };

  // const Photo = [];

  const handleMultiplePhoto = (asset) => {
    for (let i = 0; i < 10; i++) {
      if (Photo[i] == null) {
        Photo[i] = asset;
        console.log(asset);
        break;
      }
    }
    console.log("photo", Photo);
    setImage(Photo);
  };

  const contentStyle = {
    height: "100px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  const BestSellerHandle = () => {
    setBestSeller(1);
    setViewAllButton(0);
  };

  const ViewAllHandle = () => {
    setBestSeller(0);
    setViewAllButton(1);
  };
  return (
    <>
      {showModal !== true && (
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                      Products{" "}
                    </h1>
                  </div>

                  {/* Right: Actions */}
                  <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    {/* Delete button */}
                    <ProductDelete selectedItems={selectedItems} />
                    {/* Dropdown */}
                    {/* <DateSelect /> */}
                    {/* Filter button */}

                    {/* Add customer button */}
                    {viewAllButton ? (
                      <button
                        className="btn bg-yellow-500 hover:bg-yellow-600 text-white focus:border-red-400"
                        onClick={BestSellerHandle}
                      >
                        <span className="hidden xs:block ">
                          View Best Seller
                        </span>
                      </button>
                    ) : (
                      <button
                        className="btn bg-yellow-500 hover:bg-yellow-600 text-white focus:border-red-400"
                        onClick={ViewAllHandle}
                      >
                        <span className="hidden xs:block ">View All</span>
                      </button>
                    )}
                    <button
                      className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                      onClick={() => setShowModal(true)}
                    >
                      <svg
                        className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                      </svg>
                      <span className="hidden xs:block ml-2">Add Product</span>
                    </button>
                  </div>
                </div>
                {bestSeller ? (
                  <BestProductsTable
                    selectedItems={handleSelectedItems}
                    handleMultiplePhoto={handleMultiplePhoto}
                    updatePhoto={Photo}
                  />
                ) : (
                  <ProductsTable
                    selectedItems={handleSelectedItems}
                    handleMultiplePhoto={handleMultiplePhoto}
                    updatePhoto={Photo}
                  />
                )}

                {/* Table */}
                {/* Pagination */}
              </div>
            </main>
          </div>
        </div>
      )}
      {showModal ? (
       
        <AddProducts />
      ) : null}
    </>
  );
}

export default Products;
