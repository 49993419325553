import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration (1).png";
import { message } from "antd";
import "antd/dist/antd.css";
import logo from "../images/YJFMlogo.png";

import { useHistory } from "react-router-dom";

function Signin() {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const submitForm = (e) => {
    e.preventDefault();
    // console.log(pass);

    var axios = require("axios");
    var data = JSON.stringify({
      email: email,
      password: pass,
    });

    var config = {
      method: "post",
      url: "https://adminapi.youjifresh.com/admin/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (res) {
        // console.log(JSON.stringify(res.data));
        // console.log(res.data.data);
        sessionStorage.setItem("youji_token", res.data.data.token);
        sessionStorage.setItem(
          "youji_user_name",
          res.data.data.user.firstname + " " + res.data.data.user.lastname
        );
        sessionStorage.setItem("youji_user_id", res.data.data.user.id);
        setEmail("");
        setPass("");
        message.success("Logged In Successfully");
        history.push("/dashboard");
      })
      .catch(function (error) {
        console.log(error);
        message.error(error.response.data.message);
      });
  };

  return (
    <main className="bg-white ">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={logo} width="120" height="200" alt="Logo"></img>
                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto px-4 py-8 ">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                Welcome back! ✨
              </h1>
              {/* Form */}
              <form onSubmit={submitForm}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        // console.log(e.target.value);
                      }}
                      className="form-input w-full"
                    ></input>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-input w-full"
                      onChange={(e) => setPass(e.target.value)}
                      value={pass}
                      placeholder="Password"
                    ></input>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <button
                    type="submit"
                    className="btn bg-yellow-500 hover:bg-yellow-600 text-white w-full"
                  >
                    Sign In
                  </button>
                </div>
              </form>
              {/* Footer */}
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
          <img
            className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div>
      </div>
    </main>
  );
}

export default Signin;
