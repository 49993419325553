import React from "react";
import { message } from "antd";
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';


function ProductDelete({ selectedItems }) {
  console.log(selectedItems);
  
  const { confirm } = Modal;
  
  function showDeleteConfirm() {
    confirm({
      title: 'Are you sure you want to delete this entry?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
      if(selectedItems){
      
      selectedItems.forEach(i => {
        
        var axios = require("axios");
        var config = {
          method: "delete",
          url: `https://adminapi.youjifresh.com/products/${i}`,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            "Content-Type": "application/json",
          },
        };
    
        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            message.success(`Product Deleted`);      
          })
          .catch(function (error) {
            console.log(error);
            message.error(error.response.data.message);
          });
      });
      
      setTimeout(() => {
        window.location.reload()
      }, 500);
      
    }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }



  return (
    <>
    <div className={`${selectedItems.length < 1 && "hidden"}`}>
      <div className="flex items-center">
        <div className="hidden xl:block text-sm italic mr-2 whitespace-nowrap">
          <span>{selectedItems.length}</span> items selected
        </div>
        <button
          className="btn bg-white border-gray-200 hover:border-gray-300 text-red-500 hover:text-red-600"
          onClick={showDeleteConfirm}
        >
          Delete
        </button>
      </div>
    </div>

    </>
  );
}

export default ProductDelete;
