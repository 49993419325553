import React, { useState, useEffect } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DeleteButton from "../partials/actions/DeleteButton";
import PaginationClassic from "../components/PaginationClassic";
import UsersTable from "../partials/users/UsersTable";
import { message } from "antd";
import axios from "axios";

function Users() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [products, setProducts] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");


  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const AddProduct = (e) => {
    e.preventDefault();
    var axios = require("axios");
    var data = JSON.stringify({
      Name: name,
      price: price,
      description: description,
      category: category,
    });

    var config = {
      method: "post",
      url: "https://adminapi.youjifresh.com/products",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        message.success("Product Added");
      })
      .catch(function (error) {
        console.log(error);
        message.error(error.response.data.message);
      });
  };

  useEffect(() => {
    var axios = require("axios");

    var config = {
      method: "get",
      url: "https://adminapi.youjifresh.com/products",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setName(response.data.Name);
        setPrice(response.data.price);
        setCategory(response.data.category);
        setDescription(response.data.description);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const clearForm = () => {
    setName("");
    setPrice("");
    setCategory("");
    setDescription("");
  };

  useEffect(() => {
    axios
      .get("https://adminapi.youjifresh.com/products", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        setProducts(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Users
                </h1>
                
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Delete button */}
                {/* <DeleteButton selectedItems={selectedItems} /> */}
                {/* Dropdown */}
                {/* <DateSelect /> */}
                {/* Filter button */}

                {/* Add customer button */}
                {/* <button
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                  onClick={() => setShowModal(true)}
                >
                  <svg
                    className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Add User</span>
                </button> */}
              </div>
            </div>

            {/* Table */}
            <UsersTable selectedItems={handleSelectedItems} />

            {/* Pagination */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Users;
