import React, { useState, useEffect } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DeleteButton from "../partials/actions/DeleteButton";
import DateSelect from "../components/DateSelect";
import PaginationClassic from "../components/PaginationClassic";
import { message } from "antd";
import axios from "axios";
import ReviewTable from "../partials/review/ReviewTable";
import ReviewPublish from "../partials/actions/ReviewPublish";

function Reviews() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [products, setProducts] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("");
  const [review, setReview] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  //   const AddReview = (e) => {
  //     e.preventDefault();
  //     var axios = require("axios");
  //     var data = JSON.stringify({
  //       id: id,
  //       reiview_describe: review,
  //       Name: name,
  //       users: user,
  //     });

  //     var config = {
  //       method: "post",
  //       url: "https://adminapi.youjifresh.com/products",
  //       headers: {
  //         Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     axios(config)
  //       .then(function (response) {
  //         console.log(JSON.stringify(response.data));
  //         message.success("Review Added");
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //         message.error(error.response.data.message);
  //       });
  //   };

  //   useEffect(() => {
  //     var axios = require("axios");

  //     var config = {
  //       method: "get",
  //       url: "https://adminapi.youjifresh.com/products",
  //       headers: {
  //         Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
  //         "Content-Type": "application/json",
  //       },
  //     };

  //     axios(config)
  //       .then(function (response) {
  //         console.log(JSON.stringify(response.data));
  //         setName(response.data.Name);
  //         setpoints(response.data.points);
  //         setCategory(response.data.category);
  //         setDescription(response.data.description);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   }, []);

  const clearForm = () => {
    setId("");
    setReview("");
    setName("");
    setDescription("");
  };

  useEffect(() => {
    axios
      .get("https://adminapi.youjifresh.com/products", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        setReview(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0 mr-2">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Reviews{" "}
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Delete button */}
                <ReviewPublish selectedItems={selectedItems} />
                {/* Dropdown */}
                {/* <DateSelect /> */}
                {/* Filter button */}

                {/* Add customer button */}
            
              </div>
            </div>
            {showModal ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden  overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl ">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*header*/}
                      <div className="flex items-start justify-between p-5 border-b border-solid bg-gray-800 border-blueGray-200 rounded-t">
                        <h3 className="text-3xl mr-3 pr-3 font-semibold text-gray-400">
                          Add Reviews
                        </h3>
                        <button
                          className="p-1 ml-auto bg-transparent border-0 text-red-100 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          onClick={() => setShowModal(false)}
                        >
                          <span className="bg-transparent text-blue-600 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                          </span>
                        </button>
                      </div>
                      {/*body*/}
                      <div className="relative p-6 flex-auto">
                        {/* <form class="w-full max-w-lg" id="form_add_review">
                          
                        </form> */}
                      </div>
                      {/*footer*/}
                      <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          //  f
                          className=" btn  content-start bg-gray-400 hover:bg-gray-600 text-white mr-2"
                        >
                          <span className="hidden xs:block ml-2">Clear</span>
                        </button>
                        <div className="justify-items-end ">
                          <button
                            className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
                            onClick={() => setShowModal(false)}
                          >
                            <span className="hidden xs:block ml-2">CANCEL</span>
                          </button>
                          <button
                            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                            // onClick={AddReview}
                          >
                            <span className="hidden xs:block ml-2">
                              Add Review
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}

            {/* Table */}
            <ReviewTable selectedItems={handleSelectedItems} />

            {/* Pagination */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Reviews;
