import React, { useState, useEffect } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DeleteButton from "../partials/actions/DeleteButton";
import DateSelect from "../components/DateSelect";
import PaginationClassic from "../components/PaginationClassic";
import { message } from "antd";
import axios from "axios";
import ImageListingComponent from "../components/ImageListingComponent";
import AssetDelete from "../partials/actions/AssetDelete";
import _ from "loadsh";
import PaginationAssets from "../components/PaginationAssets";

function MediaLibrary() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [assets, setAssets] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [files, setFiles] = useState({});
  const [altText, setAltText] = useState("");

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(8);
  const [Len, setLen] = useState();

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
    console.log(selectedItems);
  };

  const formElement = document.querySelector("form");

  // submit single files
  // const handleChange = (event) => {
  //   setFile(event.target.files[0]);
  //   console.log(event.target.files);
  // };
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log("File upload ", file);
  //   const imgFile = file;
  //   const formData = new FormData();
  //   formData.append("files", imgFile);
  //   axios({
  //     method: "post",
  //     url: "https://adminapi.youjifresh.com/upload/",
  //     headers: {
  //       Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
  //       "Content-Type": "application/json",
  //     },
  //     data: formData,
  //   })
  //     .then(function (response) {
  //       console.log(JSON.stringify(response.data));
  //       message.success("Asset Added");
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       message.error(error.response.data.message);
  //     });
  //   // setTimeout(() => {
  //   //   window.location.reload();
  //   // }, 500);
  //   setShowModal(false);
  // };
  //  Multiple files upload
  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log("File upload ", files);
    setLoading(true);
    const formData = new FormData();
    _.forEach(files, (file) => {
      formData.append("files", file);
      // console.log(file);
    });
    console.log(formData);
    axios({
      method: "post",
      url: "https://adminapi.youjifresh.com/upload/",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
        "Content-Type": "application/json",
      },
      data: formData,
    })
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        message.success("Asset Added");
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(function (error) {
        // console.log(error);
        message.error(error.response.data.message);
      });

    setShowModal(false);
  };

  const clearAsset = () => {
    setFiles([]);
    setLen();
  };

  useEffect(() => {
    axios
      .get("https://adminapi.youjifresh.com/upload/files", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        // console.log(response.data);
        setAssets(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentAsset = assets.slice(indexOfFirstPost, indexOfLastPost);

  const [preView, setPreView] = useState();

  const ImageThumb = ({ image }) => {
    return <img src={URL.createObjectURL(image)} alt={image.name} />;
  };

  console.log(files);
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          {loading ? (
            <div className="align-center items-center ml-auto mr-auto l-0 r-0  p-10">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-amber-600"></div>
            </div>
          ) : null}
          <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className=" sm:flex  sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0 mr-2">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Assests
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Delete button */}
                <AssetDelete selectedItems={selectedItems} />
                {/* Dropdown */}
                {/* Filter button */}

                {/* Add customer button */}
                <button
                  className=" w-full btn bg-yellow-500 hover:bg-yellow-600 text-white"
                  onClick={() => setShowModal(true)}
                >
                  <svg
                    className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="xs:block"> Upload Assets</span>
                </button>
              </div>
            </div>
            {showModal ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden  overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
                  {/* <div className="relative w-100 my-6 mx-auto max-w-9xl "> */}
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-8/12 md:w3/6 bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div class="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
                      {" "}
                      <h3 className="w-full text-3xl mr-3 pr-3 font-semibold text-gray-400">
                        Upload Assets
                      </h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-red-100 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="bg-transparent text-blue-600 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}

                    {/* This is main */}
                    <div className="m-4">
                      <div class="flex flex-col items-center justify-center pt-7"></div>
                      <input
                        multiple
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          setFiles(e.target.files);
                          setLen(files.length);
                        }}
                      />
                    </div>
                    {/*footer*/}
                    <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
                      <button
                        onClick={clearAsset}
                        className=" btn  content-start bg-gray-400 hover:bg-gray-600 text-white mr-2 xs:hidden"
                      >
                        <span className="w-xs-16">Clear</span>
                      </button>
                      <div className="justify-items-end ">
                        <button
                          className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
                          onClick={() => setShowModal(false)}
                        >
                          <span className=" xs:block">Cancel</span>
                        </button>
                        <button
                          className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                          onClick={handleSubmit}
                        >
                          <span className=" xs:block">Upload</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}

            <ImageListingComponent
              selectedItems={handleSelectedItems}
              loading={loading}
            />
          </div>
        </main>
      </div>
    </div>
  );
}

export default MediaLibrary;
