import React, { useState, useEffect } from "react";
import { DatePicker, message } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";

function CouponTableItem(props) {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [change, setChange] = useState();

  const [percentage, setPercentage] = useState();
  const [active, setActive] = useState();
  const [coupon_code, setCoupon_code] = useState();
  const [value, setValue] = useState();
  const [maxValue, setMaxValue] = useState();
  const [expiry_date, setExpiry_date] = useState();
  const [id, setId] = useState();
  const [minValue, setMinValue] = useState();
  const [usage, setUsage] = useState();

  const [uminValue, setUMinValue] = useState();
  const [uusage, setUUsage] = useState();
  const [upercentage, setUPercentage] = useState();
  const [uactive, setUActive] = useState();
  const [ucoupon_code, setUCoupon_code] = useState();
  const [uvalue, setUValue] = useState();
  const [umaxValue, setUMaxValue] = useState();
  const [uexpiry_date, setUExpiry_date] = useState();
  const [uid, setUId] = useState();

  useEffect(() => {
    setPercentage(props.percentage);
    setActive(props.active);
    setCoupon_code(props.coupon_code);
    setValue(props.value);
    setMaxValue(props.max_value);
    setExpiry_date(props.expiry_date);
    setId(props.id);
    setMinValue(props.minValue);
    setUsage(props.usage);

    setUMinValue(props.minValue);
    setUUsage(props.usage);
    setUPercentage(props.percentage);
    setUActive(props.active);
    setUCoupon_code(props.coupon_code);
    setUValue(props.value);
    setUMaxValue(props.max_value);
    setUExpiry_date(props.expiry_date);
    setUId(props.id);
  }, []);

  const mouseover = () => {
    document.getElementById(`${props.coupon_code}`).style.backgroundColor =
      "#F0F0F0";
  };
  const mouseout = () => {
    document.getElementById(`${props.coupon_code}`).style.backgroundColor =
      "white";
  };

  const UpdateCoupon = (id) => {
    return (e) => {
      e.preventDefault();
      if (
        upercentage === undefined ||
        upercentage === -1 ||
        uactive === undefined ||
        uactive === -1 ||
        ucoupon_code === "" ||
        uvalue === "" ||
        umaxValue === "" ||
        uexpiry_date === "" ||
        uexpiry_date === null
      ) {
        message.error("Required field/s empty");
      } else {
        var axios = require("axios");

        var data = JSON.stringify({
          Percentage: upercentage ? upercentage : percentage,
          Active: uactive ? uactive : active,
          coupon_code: ucoupon_code ? ucoupon_code : coupon_code,
          Value: uvalue ? uvalue : value,
          Max_value: umaxValue ? umaxValue : maxValue,
          Expiry_Date: uexpiry_date ? uexpiry_date : expiry_date,
          min_cart_value: uminValue ? uminValue : minValue,
          usage: uusage ? uusage : usage
        });

        var config = {
          method: "put",
          url: `https://adminapi.youjifresh.com/coupons/${id}`,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            message.success("Coupon Updated");
            setChange(false);
            setShowModal(false);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          })
          .catch(function (error) {
            console.log(error);
            message.error(error.response.data.message);
          });
      }
      setUpdate(false);
    };
  };

  const cancel = () => {
    setShowModal(false);
    setPercentage(props.percentage);
    setActive(props.active);
    setCoupon_code(props.coupon_code);
    setValue(props.value);
    setMaxValue(props.max_value);
    setExpiry_date(props.expiry_date);

    setUPercentage(props.percentage);
    setUActive(props.active);
    setUCoupon_code(props.coupon_code);
    setUValue(props.value);
    setUMaxValue(props.max_value);
    setUExpiry_date(props.expiry_date);

    if (change === true) {
      window.alert("All the changes will be discarded");

      setUPercentage(props.percentage);
      setUActive(props.active);
      setUCoupon_code(props.coupon_code);
      setUValue(props.value);
      setUMaxValue(props.max_value);
      setUExpiry_date(props.expiry_date);

      setShowModal(false);
      message.warning("Changes discarded");

      setChange(false);
    }
    setUpdate(false);
  };

  return (
    <>
      <tbody className="text-sm">
        <tr>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
            <div className="flex items-center">
              <label className="inline-flex">
                <span className="sr-only">Select</span>
                <input
                  id={props.id}
                  className="form-checkbox"
                  type="checkbox"
                  onChange={props.handleClick}
                  checked={props.isChecked}
                />
              </label>
            </div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="flex items-center text-gray-800">
              <div className="font-medium text-light-blue-500">{props.id}</div>
            </div>
          </td>
          <td
            onClick={() =>
              history.push("/updateCoupon", {
                percentage,
                upercentage,
                active,
                uactive,
                coupon_code,
                ucoupon_code,
                value,
                uvalue,
                maxValue,
                umaxValue,
                expiry_date,
                uexpiry_date,
                id,
                minValue,
                uminValue,
                usage,
                uusage
              })
            }
            className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
            style={{ cursor: "pointer" }}
            id={props.coupon_code}
            onMouseOver={mouseover}
            onMouseOut={mouseout}
          >
            <div>{props.coupon_code}</div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-medium text-gray-800">{props.value}</div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-medium text-gray-800">{props.expiry_date}</div>
          </td>
        </tr>
      </tbody>

      {/* {showModal ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden  overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl ">
               
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
       
                      <div className="flex items-start justify-between p-5 border-b border-solid bg-gray-800 border-blueGray-200 rounded-t">
                        <h3 className="text-3xl font-semibold text-gray-400">
                          Update Coupon
                        </h3>
                        <button
                          className="p-1 ml-auto bg-transparent border-0 text-red-100 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          onClick={() => setShowModal(false)}
                        >
                          <span className="bg-transparent text-blue-600 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                          </span>
                        </button>
                      </div>
         
                      <div className="relative p-6 flex-auto">
                        <form class="w-full max-w-lg" id="form_add_Coupon">
                          <div class="flex flex-wrap -mx-2 mb-2">
                            <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_percentage"
                              >
                                PERCENTAGE
                              </label>
                              <div className='relative'>
                            <select
                                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="form__coupon_percentage"
                                  value={percentage?percentage:upercentage}
                                  onChange={(e) => {
                                    setUpdate(true)
                                    if((percentage || upercentage)!==e.target.value){
                                      setChange(true)
                                    }
                                    setPercentage();
                                    setUPercentage(e.target.value)
                                    console.log(e.target.value);
                                  }}
                                >
                                  <option value={percentage} selected>{percentage===true?'True':percentage===false?'False':'Select'}</option>
                                  <option value={true} hidden={percentage===true}>True</option>
                                  <option value={false} hidden={percentage===false}>False</option> 
                                  
                                  
                                </select>
                                </div>
                            </div>

                            <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_active"
                              >
                                Active
                              </label>
                              <div className='relative'>
                            <select
                                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="form__coupon_active"
                                  value={active?active:uactive}
                                  onChange={(e) => {
                                    setUpdate(true)
                                    if((active || uactive)!==e.target.value){
                                      setChange(true)
                                    }
                                    setActive();
                                    setUActive(e.target.value)
                                    console.log(e.target.value);
                                  }}
                                >
                                  <option value={active} selected>{active===true?'True':active===false?'False':'Select'}</option>
                                  <option value={true} hidden={active===true}>True</option>
                                  <option value={false} hidden={active===false}>False</option>         
                                  
                                  
                                </select>
                                </div>
                            </div>

                            <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_code"
                              >
                                Coupon Code
                              </label>
                              {console.log(coupon_code)}
                              <input
                                class="appearance-none block text-clip resize-y overflow-hidden w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__coupon_code"
                                type="text"
                                value={coupon_code?coupon_code:ucoupon_code}
                                onChange={(e) => {
                                  setUpdate(true)
                                  if((coupon_code || ucoupon_code)!==e.target.value){
                                    setChange(true)
                                  }
                                  setCoupon_code();
                                  setUCoupon_code(e.target.value)
                                  console.log(e.target.value);
                                }}
                                placeholder="Coupon Code"
                              />
                            </div>

                            <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_value"
                              >
                                Value
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__coupon_value"
                                type="number"
                                value={value?value:uvalue}
                                onChange={(e) => {
                                  setUpdate(true)
                                  if((value || uvalue)!==e.target.value){
                                    setChange(true)
                                  }
                                  setValue();
                                  setUValue(e.target.value)
                                  console.log(e.target.value);
                                }}
                                placeholder="ex. 10"
                              />
                            </div>


                            <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_mvalue"
                              >
                                MAX VALUE
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__coupon_mvalue"
                                type="number"
                                value={maxValue?maxValue:umaxValue}
                                onChange={(e) => {
                                  setUpdate(true)
                                  if((maxValue || umaxValue)!==e.target.value){
                                    setChange(true)
                                  }
                                  setMaxValue();
                                  setUMaxValue(e.target.value)
                                  console.log(e.target.value);
                                }}
                                placeholder="ex. 10"
                              />
                            </div>
                          

                            <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_exp_date"
                              >
                                Expiry Date
                              </label>
                              <div class="relative">
                              
                                <DatePicker
                                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="form__coupon_exp_date"
                                  isclearable
                                  value={expiry_date!==null?moment.utc(expiry_date):uexpiry_date!==null?moment.utc(uexpiry_date):null}
                                  onChange={(date) => {
                                    setUpdate(true)
                                    setChange(true)
                                    setExpiry_date(null);
                                    setUExpiry_date(date)
                                    console.log(date)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                     
                      <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
                        
                        <div className="justify-items-end ">
                          <button
                            className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
                            onClick={cancel}
                          >
                            <span className="hidden xs:block">Cancel</span>
                          </button>
                          {
                            update ? 
                            <button
                            className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                            onClick={UpdateCoupon(props.id)}
                          >
                            <span className="hidden xs:block">
                              Update 
                            </span>
                          </button>: <button
                            className="btn bg-yellow-500 opacity-50 text-white"
                            disabled
                          >
                            <span className="hidden xs:block">
                              Update 
                            </span>
                          </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null} */}
    </>
  );
}

export default CouponTableItem;
