import React, { useState, useEffect } from "react";
import { message, Carousel } from "antd";
import axios from "axios";
import ProductImageListing from "../../components/ProductImageListing";
import UpdateProduct from "./UpdateProducts";
import { useHistory } from "react-router-dom";

function ProductsTableItem(props) {
  const history = useHistory();

  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [cat, setCat] = useState([]);
  const [update, setUpdate] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [change, setChange] = useState();
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState();
  const [stock, setStock] = useState();
  const [slug, setSlug] = useState();
  const [meta_title, setMeta_title] = useState();
  const [meta_description, setMeta_description] = useState();
  const [shipping, setShipping] = useState();
  const [order_cutoff_time, setOrder_cutoff_time] = useState();
  const [storage, setStorage] = useState();
  const [onsale, setOnsale] = useState();
  const [catid, setCatid] = useState();
  const [url, seturl] = useState();

  const [uname, setUName] = useState("");
  const [uprice, setUPrice] = useState("");
  const [ucategory, setUCategory] = useState("");
  const [udescription, setUDescription] = useState();
  const [ustock, setUStock] = useState();
  const [uslug, setUSlug] = useState();
  const [umeta_title, setUMeta_title] = useState();
  const [umeta_description, setUMeta_description] = useState();
  const [ushipping, setUShipping] = useState();
  const [uorder_cutoff_time, setUOrder_cutoff_time] = useState();
  const [ustorage, setUStorage] = useState();
  const [uonsale, setUOnsale] = useState();
  const [ucatid, setUCatid] = useState();

  const [Image, setImage] = useState([]);
  const [UImage, setUImage] = useState([]);
  const [PhotoId, setPhotoId] = useState("");
  const [PhotoModal, setPhotoModal] = useState(false);
  const [Url, setUrl] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [uphoto, setUphoto] = useState([]);
  const [photo, setPhoto] = useState([]);
  const [id, setid] = useState();
  const [bestseller, setBestseller] = useState();
  const [ubestseller, setUbestseller] = useState();
  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  useState(() => {
    setName(props.name);
    setCategory(props.category);
    setPrice(props.price);
    setDescription(props.description);
    setStock(props.stock);
    setSlug(props.slug);
    setMeta_title(props.meta_title);
    setMeta_description(props.meta_description);
    setShipping(props.shipping);
    setOrder_cutoff_time(props.order_cutoff_time);
    setStorage(props.storage);
    setOnsale(props.onsale);
    setCatid(props.catid);
    seturl(props.photo.map((p) => p.url));
    setPhoto(props.photo);
    setid(props.id);
    setBestseller(props.bestseller);
    console.log("product table item", id);
  }, []);

  useEffect(() => {
    setName(props.name);
    setCategory(props.category);
    setPrice(props.price);
    setDescription(props.description);
    setStock(props.stock);
    setSlug(props.slug);
    setMeta_title(props.meta_title);
    setMeta_description(props.meta_description);
    setShipping(props.shipping);
    setOrder_cutoff_time(props.order_cutoff_time);
    setStorage(props.storage);
    setOnsale(props.onsale);
    setCatid(props.catid);
    seturl(props.photo.map((p) => p.url));
    setPhoto(props.photo);
    setBestseller(props.bestseller);

    setUName(props.name);
    setUCategory(props.category);
    setUPrice(props.price);
    setUDescription(props.description);
    setUStock(props.stock);
    setUSlug(props.slug);
    setUMeta_title(props.meta_title);
    setUMeta_description(props.meta_description);
    setUShipping(props.shipping);
    setUOrder_cutoff_time(props.order_cutoff_time);
    setUStorage(props.storage);
    setUOnsale(props.onsale);
    setUCatid(props.catid);
    setUphoto(props.photo);
    setUbestseller(props.bestseller);
  }, []);

  useEffect(() => {
    axios
      .get(`https://adminapi.youjifresh.com/categories`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setCat(response.data);
        // console.log(cat)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const UpdateProduct = (id) => {
    return (e) => {
      e.preventDefault();

      if (uname === "" || udescription === "" || uprice === "") {
        message.error("Required field/s empty");
      } else {
        var axios = require("axios");

        var data = JSON.stringify({
          Name: uname ? uname : name,
          price: uprice ? uprice : price,
          description: udescription ? udescription : description,
          category: ucatid || ucatid === "" ? ucatid : catid,
          stocks: ustock ? ustock : stock ? stock : null,
          slug: uslug || uslug === "" ? uslug : slug,
          meta_title:
            umeta_title || umeta_title === "" ? umeta_title : meta_title,
          meta_description:
            umeta_description || umeta_description === ""
              ? umeta_description
              : meta_description,
          shipping: ushipping || ushipping === "" ? ushipping : shipping,
          order_cut_off_time:
            uorder_cutoff_time || uorder_cutoff_time === ""
              ? uorder_cutoff_time
              : order_cutoff_time,
          storage: ustorage || storage === "" ? ustorage : storage,
          OnSale: uonsale ? uonsale : onsale,
          photo: uphoto ? uphoto : "",
          bestseller: ubestseller ? ubestseller : bestseller,
        });

        var config = {
          method: "put",
          url: `https://adminapi.youjifresh.com/products/${id}`,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            message.success("Product Updated");
            setChange(false);
            setShowModal(false);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          })
          .catch(function (error) {
            console.log(error);
            message.error(error.response.data.message);
          });
      }
      setUpdate(false);
    };
  };

  const cancel = () => {
    setShowModal(false);
    setName(props.name);
    setCategory(props.category);
    setDescription(props.description);
    setPrice(props.price);
    setStock(props.stock);
    setSlug(props.slug);
    setMeta_title(props.meta_title);
    setMeta_description(props.meta_description);
    setShipping(props.shipping);
    setOrder_cutoff_time(props.order_cutoff_time);
    setStorage(props.storage);
    setOnsale(props.onsale);
    seturl(props.photo.map((p) => p.url));
    setPhoto(props.photo);
    setBestseller(props.bestseller);
    setUphoto(props.photo);

    setUName(props.name);
    setUCategory(props.category);
    setUDescription(props.description);
    setUPrice(props.price);
    setUStock(props.stock);
    setUSlug(props.slug);
    setUMeta_title(props.meta_title);
    setUMeta_description(props.meta_description);
    setUShipping(props.shipping);
    setUOrder_cutoff_time(props.order_cutoff_time);
    setUStorage(props.storage);
    setUOnsale(props.onsale);
    setUbestseller(props.bestseller);

    if (change === true) {
      // const text =
      window.alert("All the changes will be discarded");
      // if(text===true){
      setUName(props.name);
      setUCategory(props.category);
      setUDescription(props.description);
      setUPrice(props.price);
      setUStock(props.stock);
      setUSlug(props.slug);
      setUMeta_title(props.meta_title);
      setUMeta_description(props.meta_description);
      setUShipping(props.shipping);
      setUOrder_cutoff_time(props.order_cutoff_time);
      setUStorage(props.storage);
      setUOnsale(props.onsale);
      setUCatid(props.catid);
      setPhoto(props.photo);
      setUphoto();
      setUbestseller(props.bestseller);

      setShowModal(false);
      setUrl();
      message.warning("Changes discarded");
      // }
      // else{
      //   setShowModal(true);
      //   // setName(uname?uname:name)
      //   // setCategory(ucategory?ucategory:category);
      //   // setDescription(udescription?udescription:description);
      //   // setPrice(uprice?uprice:price);
      //   // setStock(ustock?ustock:stock)
      //   // setSlug(uslug?uslug:slug)
      //   // setMeta_title(umeta_title?umeta_title:meta_title)
      //   // setMeta_description(umeta_description?umeta_description:meta_description)
      //   // setShipping(ushipping?ushipping:shipping)
      //   // setOrder_cutoff_time(uorder_cutoff_time?uorder_cutoff_time:order_cutoff_time)
      //   // setStorage(ustorage?ustorage:storage)
      //   // setOnsale(uonsale?uonsale:onsale)

      // }
      setChange(false);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    setUpdate(false);
  };

  const mouseover = () => {
    document.getElementById(`${props.name}`).style.backgroundColor = "#F0F0F0";
  };
  const mouseout = () => {
    document.getElementById(`${props.name}`).style.backgroundColor = "white";
  };

  // const handleMultiplePhoto = (asset) => {
  //   for (let i = 0; i < 10; i++) {
  //     if (updatePhoto[i] == null) {
  //       Photo[i] = asset;
  //       console.log(asset);
  //       break;
  //     }
  //   }
  //   console.log("photo", Photo);
  //   setImage(Photo);
  // };
  const handlePhotoUpdate = (e) => {
    e.preventDefault();
    console.log(props.updatePhoto);
    setPhoto();
    setUphoto(props.updatePhoto);
    console.log("pti", uphoto);
    // setImage(props)
    setUpdate(true);
    seturl();
    setPhotoModal(true);
    setChange(true);
  };

  const contentStyle = {
    height: "100px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  return (
    <>
      <tbody className="text-sm">
        <tr>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
            <div className="flex items-center">
              <label className="inline-flex">
                <span className="sr-only">Select</span>
                <input
                  id={props.id}
                  className="form-checkbox"
                  type="checkbox"
                  onChange={props.handleClick}
                  checked={props.isChecked}
                />
              </label>
            </div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="flex items-center text-gray-800">
              <div className="font-medium text-light-blue-500">{props.id}</div>
            </div>
          </td>
          <td
            onClick={() => {
              history.push("/updateProduct", {
                name,
                price,
                id,
                description,
                category,
                stock,
                photo,
                slug,
                meta_title,
                meta_description,
                shipping,
                order_cutoff_time,
                onsale,
                storage,
                catid,
                bestseller,
              });
            }}
            className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap "
            style={{ cursor: "pointer" }}
            id={props.name}
            onMouseOver={mouseover}
            onMouseOut={mouseout}
          >
            <div>
              <button
                style={{ borderStyle: "none" }}
                className="font-medium text-gray-800"
              >
                {props.name}
              </button>
            </div>
          </td>

          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
            <div className="flex items-center">
              <button
                className={`text-gray-400 hover:text-gray-500 transform ${
                  descriptionOpen && "rotate-180"
                }`}
                aria-expanded={descriptionOpen}
                onClick={() => setDescriptionOpen(!descriptionOpen)}
                aria-controls={`description-${props.id}`}
              >
                <span className="sr-only">Menu</span>
                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                  <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
                </svg>
              </button>
            </div>
          </td>

          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="text-left font-medium text-yellow-500">
              {props.price}
            </div>
          </td>

          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
            <div className="text-left font-medium text-gray-800">
              {props.category}
            </div>
          </td>

          {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
              <div className="text-left font-medium text-green-500">
                <img src={props.photo.map(p=>p.url)}></img>
              </div>
            </td> */}
        </tr>
        <tr
          id={`description-${props.id}`}
          role="region"
          className={`${!descriptionOpen && "hidden"}`}
        >
          <td colSpan="10" className="px-2 first:pl-5 last:pr-5 py-3">
            <div className="flex items-center bg-gray-50 p-3 -mt-3">
              <div className="italic">{props.description}</div>
            </div>
          </td>
        </tr>
      </tbody>

      {showModal ? (
        <>
          <UpdateProduct />
          <UpdateProduct />
        </>
      ) : //   <>
      //     <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none  ">
      //       <div className="relative w-auto my-6 mx-auto max-w-3xl">
      //         {/*content*/}
      //         <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-screen">
      //           {/*header*/}
      //           <div className="flex items-start justify-between p-5 border-b border-solid bg-gray-800 border-blueGray-200 rounded-t">
      //             <h3 className="text-3xl font-semibold text-gray-400">
      //               Edit Product
      //             </h3>
      //             {/* <button
      //                     className="p-1 ml-auto bg-transparent border-0 text-red-100 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
      //                     onClick={() => setShowModal(false)}
      //                   >
      //                     <span className="bg-transparent text-blue-600 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
      //                       ×
      //                     </span>
      //                   </button> */}
      //           </div>
      //           {/*body*/}
      //           <div className="relative p-6 flex-auto overflow-y-scroll">
      //             <form class="w-full max-w-lg" id="form_add_prod">
      //               <div class="flex flex-wrap -mx-2 mb-2">
      //                 <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
      //                   <label
      //                     class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      //                     for="form__product_name"
      //                   >
      //                     NAME
      //                   </label>
      //                   <input
      //                     class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      //                     id="form__product_name"
      //                     type="text"
      //                     value={name ? name : uname}
      //                     onChange={(e) => {
      //                       setUpdate(true);
      //                       if ((name || uname) !== e.target.value) {
      //                         setChange(true);
      //                       }

      //                       setName();
      //                       setUName(e.target.value);
      //                       console.log(e.target.value);
      //                     }}
      //                     placeholder="product_name"
      //                   />
      //                 </div>

      //                 <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
      //                   <label
      //                     class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      //                     for="form__product_description"
      //                   >
      //                     DESCRIPTION
      //                   </label>
      //                   <textarea
      //                     class="appearance-none block text-clip resize-y overflow-hidden w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      //                     id="form__product_description"
      //                     type="textarea"
      //                     value={description ? description : udescription}
      //                     onChange={(e) => {
      //                       setUpdate(true);
      //                       if (
      //                         (description || udescription) !== e.target.value
      //                       ) {
      //                         setChange(true);
      //                       }
      //                       setDescription();
      //                       setUDescription(e.target.value);
      //                       console.log(e.target.value);
      //                     }}
      //                     placeholder="description of the product"
      //                   />
      //                 </div>

      //                 <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
      //                   <label
      //                     class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      //                     for="form__product_price"
      //                   >
      //                     PRICE ($)
      //                   </label>
      //                   <input
      //                     class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      //                     id="form__product_price"
      //                     type="number"
      //                     value={price ? price : uprice}
      //                     onChange={(e) => {
      //                       setUpdate(true);
      //                       if ((price || uprice) !== e.target.value) {
      //                         setChange(true);
      //                       }
      //                       setPrice();
      //                       setUPrice(e.target.value);
      //                       console.log(e.target.value);
      //                     }}
      //                     placeholder="123 $"
      //                   />
      //                 </div>

      //                 <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
      //                   <label
      //                     class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      //                     for="form__product_category"
      //                   >
      //                     CATEGORY
      //                   </label>
      //                   <div class="relative">
      //                     <select
      //                       class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      //                       id="form__product_category"
      //                       value={catid ? catid : ucatid}
      //                       onChange={(e) => {
      //                         setUpdate(true);
      //                         if ((catid || ucatid) !== e.target.value) {
      //                           setChange(true);
      //                         }
      //                         setCatid();
      //                         setUCatid(e.target.value);
      //                         console.log(e.target.value);
      //                       }}
      //                     >
      //                       {cat.map((c) => (
      //                         <>
      //                           <option value={c.id}>{c.name}</option>
      //                         </>
      //                       ))}
      //                     </select>
      //                   </div>
      //                 </div>

      //                 <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0 ">
      //                   <label
      //                     class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      //                     for="form__product_stock"
      //                   >
      //                     PHOTO
      //                   </label>
      //                   <div class="relative">
      //                     <Carousel autoplay>
      //                       {photo
      //                         ? photo.map((p) => (
      //                             <div>
      //                               <h3 style={contentStyle}>
      //                                 <img
      //                                   src={p.formats.thumbnail.url}
      //                                   style={{ objectFit: "contain" }}
      //                                   height="100px"
      //                                   alt="ProductImage"
      //                                 />
      //                               </h3>
      //                             </div>
      //                           ))
      //                         : uphoto.map((p) => (
      //                             <div>
      //                               <h3 style={contentStyle}>
      //                                 <img
      //                                   src={p.formats.thumbnail.url}
      //                                   style={{ objectFit: "contain" }}
      //                                   height="100px"
      //                                   alt="ProductImage"
      //                                 />
      //                               </h3>
      //                             </div>
      //                           ))}
      //                     </Carousel>
      //                   </div>
      //                   <button
      //                     className="btn bg-yellow-500 w-full  hover:bg-yellow-600 text-white mt-2"
      //                     onClick={handlePhotoUpdate}
      //                   >
      //                     <span className="hidden xs:block">Change</span>
      //                   </button>
      //                 </div>

      //                 <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
      //                   <label
      //                     class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      //                     for="form__product_stock"
      //                   >
      //                     STOCK
      //                   </label>
      //                   <div class="relative">
      //                     <input
      //                       class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      //                       id="form__product_stock"
      //                       type="number"
      //                       value={stock ? stock : ustock}
      //                       onChange={(e) => {
      //                         setUpdate(true);
      //                         if ((stock || ustock) !== e.target.value) {
      //                           setChange(true);
      //                         }
      //                         setStock();
      //                         setUStock(e.target.value);
      //                         console.log(e.target.value);
      //                       }}
      //                       placeholder="stock"
      //                     />
      //                   </div>
      //                 </div>

      //                 <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
      //                   <label
      //                     class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      //                     for="form__product_slug"
      //                   >
      //                     Slug
      //                   </label>
      //                   <div class="relative">
      //                     <input
      //                       class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      //                       id="form__product_slug"
      //                       type="text"
      //                       value={slug ? slug : uslug}
      //                       onChange={(e) => {
      //                         setUpdate(true);
      //                         if ((slug || uslug) !== e.target.value) {
      //                           setChange(true);
      //                         }
      //                         setSlug();
      //                         setUSlug(e.target.value);
      //                         console.log(e.target.value);
      //                       }}
      //                       placeholder="slug"
      //                     />
      //                   </div>
      //                 </div>

      //                 <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
      //                   <label
      //                     class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      //                     for="form__product_meta_title"
      //                   >
      //                     Meta Title
      //                   </label>
      //                   <div class="relative">
      //                     <input
      //                       class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      //                       id="form__product_meta_title"
      //                       type="text"
      //                       value={meta_title ? meta_title : umeta_title}
      //                       onChange={(e) => {
      //                         setUpdate(true);
      //                         if (
      //                           (meta_title || umeta_title) !== e.target.value
      //                         ) {
      //                           setChange(true);
      //                         }
      //                         setMeta_title();
      //                         setUMeta_title(e.target.value);
      //                         console.log(e.target.value);
      //                       }}
      //                       placeholder="meta title"
      //                     />
      //                   </div>
      //                 </div>

      //                 <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
      //                   <label
      //                     class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      //                     for="form__product_meta_description"
      //                   >
      //                     Meta Description
      //                   </label>
      //                   <div class="relative">
      //                     <input
      //                       class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      //                       id="form__product_meta_description"
      //                       type="text"
      //                       value={
      //                         meta_description
      //                           ? meta_description
      //                           : umeta_description
      //                       }
      //                       onChange={(e) => {
      //                         setUpdate(true);
      //                         if (
      //                           (meta_description || umeta_description) !==
      //                           e.target.value
      //                         ) {
      //                           setChange(true);
      //                         }
      //                         setMeta_description();
      //                         setUMeta_description(e.target.value);
      //                         console.log(e.target.value);
      //                       }}
      //                       placeholder="meta description"
      //                     />
      //                   </div>
      //                 </div>

      //                 <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
      //                   <label
      //                     class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      //                     for="form__product_shipping"
      //                   >
      //                     Shipping
      //                   </label>
      //                   <div class="relative">
      //                     <input
      //                       class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      //                       id="form__product_shipping"
      //                       type="text"
      //                       value={shipping ? shipping : ushipping}
      //                       onChange={(e) => {
      //                         setUpdate(true);
      //                         if ((shipping || ushipping) !== e.target.value) {
      //                           setChange(true);
      //                         }
      //                         setShipping();
      //                         setUShipping(e.target.value);
      //                         console.log(e.target.value);
      //                       }}
      //                       placeholder="shipping"
      //                     />
      //                   </div>
      //                 </div>

      //                 <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
      //                   <label
      //                     class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      //                     for="form__product_o"
      //                   >
      //                     Order Cut Off Time
      //                   </label>
      //                   <div class="relative">
      //                     <input
      //                       class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      //                       id="form__product_o"
      //                       type="text"
      //                       value={
      //                         order_cutoff_time
      //                           ? order_cutoff_time
      //                           : uorder_cutoff_time
      //                       }
      //                       onChange={(e) => {
      //                         setUpdate(true);
      //                         if (
      //                           (order_cutoff_time || uorder_cutoff_time) !==
      //                           e.target.value
      //                         ) {
      //                           setChange(true);
      //                         }
      //                         setOrder_cutoff_time();
      //                         setUOrder_cutoff_time(e.target.value);
      //                         console.log(e.target.value);
      //                       }}
      //                       placeholder="order cutoff time"
      //                     />
      //                   </div>
      //                 </div>

      //                 <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
      //                   <label
      //                     class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      //                     for="form__product_storage"
      //                   >
      //                     Storage
      //                   </label>
      //                   <div class="relative">
      //                     <input
      //                       class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      //                       id="form__product_storage"
      //                       type="text"
      //                       value={storage ? storage : ustorage}
      //                       onChange={(e) => {
      //                         setUpdate(true);
      //                         if ((storage || ustorage) !== e.target.value) {
      //                           setChange(true);
      //                         }
      //                         setStorage();
      //                         setUStorage(e.target.value);
      //                         console.log(e.target.value);
      //                       }}
      //                       placeholder="storage"
      //                     />
      //                   </div>
      //                 </div>

      //                 <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
      //                   <label
      //                     class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      //                     for="form__product_onsale"
      //                   >
      //                     Onsale
      //                   </label>
      //                   <div class="relative">
      //                     <select
      //                       class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      //                       id="form__product_onsale"
      //                       value={onsale ? onsale : uonsale}
      //                       onChange={(e) => {
      //                         setUpdate(true);
      //                         if ((onsale || uonsale) !== e.target.value) {
      //                           setChange(true);
      //                         }
      //                         setOnsale();
      //                         setUOnsale(e.target.value);
      //                         console.log(e.target.value);
      //                       }}
      //                     >
      //                       <option value={onsale} selected>
      //                         {onsale === true
      //                           ? "True"
      //                           : onsale === false
      //                           ? "False"
      //                           : "Select"}
      //                       </option>
      //                       <option value={true} hidden={onsale === true}>
      //                         True
      //                       </option>
      //                       <option value={false} hidden={onsale === false}>
      //                         False
      //                       </option>
      //                     </select>
      //                   </div>
      //                 </div>
      //               </div>
      //             </form>
      //           </div>
      //           {/*footer*/}
      //           <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
      //             <div className="justify-items-end ">
      //               <button
      //                 className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
      //                 onClick={cancel}
      //               >
      //                 <span className="hidden xs:block ">Cancel</span>
      //               </button>
      //               {update ? (
      //                 <button
      //                   className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
      //                   onClick={UpdateProduct(props.id)}
      //                 >
      //                   <span className="hidden xs:block">Update</span>
      //                 </button>
      //               ) : (
      //                 <button
      //                   className="btn bg-yellow-500 opacity-50 text-white"
      //                   disabled
      //                 >
      //                   <span className="hidden xs:block">Update</span>
      //                 </button>
      //               )}

      //               {/* modal ends  */}
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //     <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      //   </>
      // ) : null}

      // {PhotoModal ? (
      //   <>
      //     <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      //       <div className="relative w-auto my-6 mx-auto max-w-6xl">
      //         {/*content*/}
      //         <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-screen">
      //           {/*header*/}
      //           <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
      //             <h3 className="text-3xl font-semibold">Photo Select</h3>
      //           </div>
      //           {/*body*/}
      //           <div className="relative p-6 flex-auto overflow-y-scroll">
      //             <ProductImageListing
      //               selectedItems={handleSelectedItems}
      //               setPhotoId={setPhotoId}
      //               setPhotoModal={setPhotoModal}
      //               setUrl={setUrl}
      //               setImage={setImage}
      //               handleMultiplePhoto={props.handleMultiplePhoto}
      //             />
      //           </div>
      //           {/*footer*/}
      //           <div className="flex items-center justify-end p-6 border-t border-solid  border-blueGray-200 rounded-b">
      //             <button
      //               className="text-red-500  font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
      //               type="button"
      //               onClick={() => {
      //                 setPhotoModal(false);
      //                 seturl(props.photo.map((p) => p.url));
      //               }}
      //             >
      //               Close
      //             </button>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //     <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      //   </>
      null}
    </>
  );
}

export default ProductsTableItem;
