import React, { useState, useEffect } from "react";
import { message } from "antd";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

function ReviewPublish({ selectedItems }) {
  console.log(selectedItems);

  const { confirm } = Modal;

  const [published, setPublished] = useState();
  const [reviews, setreviews] = useState([]);

  useEffect(() => {
    var axios = require("axios");
    var config = {
      method: "get",
      url: `https://adminapi.youjifresh.com/reviews`,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setreviews(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (selectedItems) {
      selectedItems.forEach((i) => {
        var axios = require("axios");
        var config = {
          method: "get",
          url: `https://adminapi.youjifresh.com/reviews/${i}`,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            "Content-Type": "application/json",
          },
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            setPublished();
          })
          .catch(function (error) {
            console.log(error);
          });
      });

      // setTimeout(() => {
      //   window.location.reload()
      // }, 500);
    }
  }, []);

  function showDeleteConfirm() {
    confirm({
      title: "Are you sure you want to delete this entry?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        if (selectedItems) {
          selectedItems.forEach((i) => {
            var axios = require("axios");
            var data = JSON.stringify({
              published_at: null,
            });
            var config = {
              method: "put",
              url: `https://adminapi.youjifresh.com/reviews/${i}`,
              headers: {
                Authorization:
                  "Bearer " + sessionStorage.getItem("youji_token"),
                "Content-Type": "application/json",
              },
              data: data,
            };

            axios(config)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
              })
              .catch(function (error) {
                console.log(error);
              });
          });

          // setTimeout(() => {
          //   window.location.reload()
          // }, 500);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  return (
    <>
      <div className={`${selectedItems.length < 1 && "hidden"}`}>
        <div className="flex items-center">
          <div className="hidden xl:block text-sm italic mr-2 whitespace-nowrap">
            <span>{selectedItems.length}</span> items selected
          </div>
          <button className="btn bg-white border-gray-200 hover:border-gray-300 text-red-500 hover:text-red-600">
            Publish
          </button>

          <button
            className="btn bg-white border-gray-200 hover:border-gray-300 text-red-500 hover:text-red-600"
            onClick={showDeleteConfirm}
          >
            UnPublish
          </button>
        </div>
      </div>
    </>
  );
}

export default ReviewPublish;
