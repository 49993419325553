import React, { useState, useEffect } from "react";
import { focusHandling } from "cruip-js-toolkit";
import Orders from "./OrdersTableItem";
import { Pagination } from "antd";
import axios from "axios";
import Calendar from 'react-calendar';
import { AiFillCalendar } from 'react-icons/ai';

function OrdersTable({ selectedItems, date, setDate }) {
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://adminapi.youjifresh.com/allorders?_sort=created_at:DESC&_limit=-1`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
        setList(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    focusHandling("outline");
  }, [list]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map((li) => li.id.toString()));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  const change = (e) => {
     
  }

  const handleselect = (e) => {
    console.log(e.target.value, "select");
    if (e.target.value === "paid") {
      axios
        .get(
          `https://adminapi.youjifresh.com/allorders?payment_status=paid&_limit=-1`,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log(response.data);
          setList(response.data);
          console.log('list',list);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (e.target.value === "unpaid") {
      axios
        .get(
          `https://adminapi.youjifresh.com/allorders?payment_status=unpaid&_limit=-1`,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log(response.data);
          setList(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .get(
          `https://adminapi.youjifresh.com/allorders?_sort=created_at:DESC&_limit=-1`,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log(response.data);
          setList(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a style={{ userSelect: "none" }}>Previous</a>;
    }
    if (type === "next") {
      return <a style={{ userSelect: "none" }}>Next</a>;
    }
    return originalElement;
  }

  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexofFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = list.slice(indexofFirstOrder, indexOfLastOrder);
  const [valueDate, setValueDate] = useState('null=true');
  const [showCalendar, setShowCalendar] = useState(false)
  const paginate = (currentPage, ordersPerPage) => {
    setCurrentPage(currentPage);
  };

  function onShowSizeChange(current, pageSize) {
    setOrdersPerPage(pageSize);
  }
const searchDate = (e)=>{
   
      if(valueDate === 'null=true'   ){
         alert('No Data Available on this date')
         setValueDate(e.target.value.currentOrders);
      }
      else if(valueDate === currentOrders.created_at){
        setValueDate(currentOrders.filter(order => order.created_at && order.created_at.includes(valueDate)))
      }
      // else{
      //    setValueDate(currentOrders.filter(order => order.created_at && order.created_at.includes(valueDate)));
      // }
       
  
}


console.log('currentOrders',currentOrders);
  return (
    <>
      <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
        <header className="px-5 py-4 flex">
          <h2 className=" font-semibold text-gray-800">
            All Orders{" "}
            <span className="text-gray-400 font-medium">
              {list && list.length}
            </span>
            <span className="px-5 py-4 ">Filter :</span>
            <select onChange={handleselect}>
              <option value={-1}>All</option>
              <option value="paid">Paid</option>
              <option value="unpaid">Unpaid</option>
            </select>

        
              {/* <label className="px-5 py-4 ">Date Filter:</label>
              <input type="date" onChange={(e)=>searchDate(e)} className="w-54" placeholder="YYYY-MM-DD" id="date" name="date" value={valueDate}  /> */}
           
          </h2>

          {/*           
          <div class="flex relative mx-5 mb-1" >
            <div class="flex absolute inset-y-0 left-0 items-center h-10 w-10 pl-5 pointer-events-none cursor-pointer">
            <a className="w-22 h-22" onClick={()=>{setShowCalendar(!showCalendar)}}>
              {showCalendar?"Hide Calendar":<AiFillCalendar/>}</a>
              {showCalendar&&<Calendar className="mb-10" onChange={setValueDate} value={valueDate} />}
            </div>
             <input onChange={setValueDate} type="text" class="bg-white-500 h-10 border border-black-300 text-gray-900 sm:text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" />
          </div> */}



          {/* <button className="w-[20%] bg-black text-white font-bold py-2 px-8  mb-10 rounded focus:outline-none" 
          onClick={()=>{setShowCalendar(!showCalendar)}}>{showCalendar?"Hide Calendar":"Pick Date"}</button>
          {showCalendar&&<Calendar className="mb-10" onChange={setValueDate} value={valueDate} />} */}
        </header>
        <div>
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="table-auto w-full divide-y divide-gray-200">
              {/* Table header */}
              <thead className="text-xs uppercase text-gray-500 bg-gray-50 border-t border-gray-200">
                <tr>
                  {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="flex items-center">
                    <label className="inline-flex">
                      <span className="sr-only">Select all</span>
                      <input
                        className="form-checkbox"
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </label>
                  </div>
                 </th> */}
                  <th
                    className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                    width="5%"
                  >
                    <div className="font-semibold text-left">Id</div>
                  </th>
                  <th
                    className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                    width="5%"
                  >
                    <div className="font-semibold text-left">Username</div>
                  </th>
                  <th
                    className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                    width="5%"
                  >
                    <div className="font-semibold text-left">Payment Mode</div>
                  </th>
                  <th
                    className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                    width="5%"
                  >
                    <div className="font-semibold text-left">Total</div>
                  </th>

                  <th
                    className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                    width="5%"
                  >
                    <div className="font-semibold text-left">Order Status</div>
                  </th>

                  <th
                    className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                    width="5%"
                  >
                    <div className="font-semibold text-left">Details</div>
                  </th>
                  <th
                    className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                    width="5%"
                  >
                    <div className="font-semibold text-left">Date of Delivery</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              {currentOrders.map((order) => {
                return (
                  <>
                    {console.log('order',order.shipping_address)}
                    <Orders
                      key={order.id}
                      handleClick={handleClick}
                      isChecked={isCheck.includes(order.id.toString())}
                      id={order.id}
                      order_status={order.order_status}
                      payment_status={order.payment_status}
                      total={order.total}
                      username={order.user_name}
                      billing_address={order.billing_address}
                      shipping_address={order.shipping_address}
                      checkout_session={order.checkout_session}
                      items={order.items}
                      sub_amount={order.sub_amount}
                      discount_amount={order.discount_amount}
                      refund={order.refund}
                      coupon_code={order.coupon_code}
                      discount_type={order.discount_type}
                      free_shipping={order.free_shipping}
                      reward_value={order.reward_value}
                      created_at={order.created_at}
                      updated_at={order.updated_at}
                      date_of_delivery={order.date_of_delivery}
                    />
                  </>
                );
              })}
            </table>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-4">
        <Pagination
          current={currentPage}
          itemRender={itemRender}
          total={list.length}
          pageSize={ordersPerPage}
          onShowSizeChange={onShowSizeChange}
          onChange={paginate}
        />
      </div>
    </>
  );
}

export default OrdersTable;
