import React, { useState, useEffect } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DeleteButton from "../partials/actions/DeleteButton";
import PaginationClassic from "../components/PaginationClassic";
import CategoryTable from '../partials/categories/CategoryTable'
import { message } from "antd";
import axios from "axios";
import CategoryDelete from "../partials/actions/CategoryDelete";
import AddCategory from '../partials/categories/AddCategory'


function Category() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [categories,setCategories] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [slug,setSlug] = useState()

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };
  useEffect(()=>{
    setName('')
    setSlug('')
  },[])

  const AddProduct = (e) => {
    e.preventDefault();
    if(name===''||slug===''){
      message.error('Empty input')
    }
    else if(categories.map(c=>c.slug).includes(slug)){
      message.error('Slug value already exists, Change its value')
    }
    else{
    var axios = require("axios");
    var data = JSON.stringify({
      name:name?name:'',
      slug:slug?slug:''
    });

    var config = {
      method: "post",
      url: "https://adminapi.youjifresh.com/categories",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
        "Content-Type": "application/json"
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        message.success("Category Added");
        setTimeout(() => {
          window.location.reload()
        }, 500);
      })
      .catch(function (error) {
        console.log(error);
        message.error(error.response.data.message);
      });
      setShowModal(false);
    }
  };


  const clearForm = () => {
    setName("");
    setSlug('')
  };

  const cancel = () => {
    setShowModal(false)
    
  }

  useEffect(() => {
    axios
      .get("https://adminapi.youjifresh.com/categories", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json"
        }
      })
      .then(function (response) {
        console.log(response.data);
        setCategories(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
    {showModal!==true&&
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Categories
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Delete button */}
                <CategoryDelete selectedItems={selectedItems} />
                {/* Dropdown */}
                {/* <DateSelect /> */}
                {/* Filter button */}

                {/* Add customer button */}
                <button
                  className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                  onClick={() => setShowModal(true)}
                >
                  <svg
                    className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Add Category</span>
                </button>
              </div>
            </div>
        

            {/* Table */}
            <CategoryTable selectedItems={handleSelectedItems} />

            {/* Pagination */}
           
          </div>

          
        </main>
      </div>
    </div>
}



{showModal ? (
  // <>
  //   <div className="justify-center items-center flex overflow-x-hidden  overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
  //     <div className="relative w-auto my-6 mx-auto max-w-3xl ">
  //       {/*content*/}
  //       <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
  //         {/*header*/}
  //         <div className="flex items-start justify-between p-5 border-b border-solid bg-gray-800 border-blueGray-200 rounded-t">
  //           <h3 className="text-3xl font-semibold text-gray-400">
  //             Add Category
  //           </h3>
  //           <button
  //             className="p-1 ml-auto bg-transparent border-0 text-red-100 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
  //             onClick={() => setShowModal(false)}
  //           >
  //             <span className="bg-transparent text-blue-600 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
  //               ×
  //             </span>
  //           </button>
  //         </div>
  //         {/*body*/}
  //         <div className="relative p-6 flex-auto">
  //           <form class="w-full max-w-lg" id="form_add_prod">
  //             <div class="flex flex-wrap -mx-2 mb-2">

  //               <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
  //                 <label
  //                   class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
  //                   for="form__product_name"
  //                 >
  //                   NAME
  //                 </label>
  //                 <input
  //                   class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
  //                   id="form__product_name"
  //                   type="text"
  //                   value={name?name:''}
  //                   onChange={(e) => {
  //                     setName(e.target.value);
  //                     console.log(e.target.value);
  //                   }}
  //                   placeholder="name"
  //                 />
  //               </div>

  //               <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
  //                 <label
  //                   class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
  //                   for="form__product_slug"
  //                 >
  //                   SLUG
  //                 </label>
  //                 <input
  //                   class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
  //                   id="form__product_slug"
  //                   type="text"
  //                   value={slug?slug:''}
  //                   onChange={(e) => {
  //                     setSlug(e.target.value);
  //                     console.log(e.target.value);
  //                   }}
  //                   placeholder="slug"
  //                 />
  //               </div>

        
  //             </div>
  //           </form>
  //         </div>
  //         {/*footer*/}
  //         <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
  //           <button
  //             onClick={clearForm}
  //             className=" btn  content-start bg-gray-400 hover:bg-gray-600 text-white mr-2"
  //           >
  //             <span className="hidden xs:block">Clear</span>
  //           </button>
  //           <div className="justify-items-end ">
  //             <button
  //               className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
  //               onClick={cancel}
  //             >
  //               <span className="hidden xs:block">Cancel</span>
  //             </button>
  //             <button
  //               className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
  //               onClick={AddProduct}
  //             >
  //               <span className="hidden xs:block">
  //                 Add
  //               </span>
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  //   <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  // </>
  <><AddCategory/></>
) : null}
</>
  );
}

export default Category;
