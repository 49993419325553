export const COLUMNS = [
  {
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    Header: "Name",
    accessor: "Name",
  },
  {
    Header: "Remark",
    accessor: "remarks",
  },
  {
    Header: "Price",
    accessor: "price",
  },
];
