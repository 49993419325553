import React, { useState, useEffect } from "react";
import { focusHandling } from "cruip-js-toolkit";
import Review from "./ReviewTableItem";
import FilterButton from "../../components/DropdownFilter";
import { Pagination } from "antd";
import axios from "axios";

function ReviewTable({ selectedItems }) {
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [users, setUsers] = useState();
  const [fp, setFp] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://adminapi.youjifresh.com/reviews?_sort=created_at:DESC&_limit=-1`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response.data, "THis is the data ");
        setReviews(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  // useEffect(() => {
  //   axios
  //     .get(`https://adminapi.youjifresh.com/reviews?_sort=created_at:DESC`, {
  //       headers: {
  //         Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then(function (response) {
  //       console.log(response.data.users, "THis is the data ");
  //       setUsers(response.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);
  console.log(reviews, "this is the reviews");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews, fp]);

  useEffect(() => {
    focusHandling("outline");
  }, [reviews, fp]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (reviews) {
      setIsCheck(reviews.map((li) => li.id.toString()));
    } else if (fp) {
      setIsCheck(fp.map((li) => li.id.toString()));
    }
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  const getData = (val) => {
    console.log("getData", val);
    setReviews();
    setFp(val);
    console.log("getData", fp);
  };
  const get = (reviews) => {
    setFp([]);
    setReviews(reviews);
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a style={{ userSelect: "none" }}>Previous</a>;
    }
    if (type === "next") {
      return <a style={{ userSelect: "none" }}>Next</a>;
    }
    return originalElement;
  }

  const [reviewsPerPage, setReviewsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexofFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexofFirstReview, indexOfLastReview);

  const paginate = (currentPage, reviewsPerPage) => {
    setCurrentPage(currentPage);
  };

  useEffect(() => {
    axios
      .get(
        `https://adminapi.youjifresh.com/content-manager/collection-types/application::review.review`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className=" bg-white shadow-lg rounded-sm border border-gray-200 relative">
        <header className="px-5 py-4 ">
          <h2 className="font-semibold text-gray-800">
            All reviews
            <span className="text-gray-400 font-medium p-4">
              {reviews && reviews.length}
            </span>
            {/*          
          <FilterButton
            sendData={getData}
            clearData={get}
            align="left"
            reviews={reviews}
            setReviews={setReviews}
          /> */}
          </h2>
        </header>

        <div>
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="table-auto w-full divide-y divide-gray-200">
              {/* Table header */}
              <thead className="text-xs uppercase text-gray-500 bg-gray-50 border-t border-gray-200">
                <tr>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Id</div>
                  </th>
                  {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Name</div>
                </th> */}

                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Review</div>
                  </th>

                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">
                      Publish Status
                    </div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              {reviews ? (
                currentReviews.map((review) => {
                  let username = "username";
                  console.log(review.users[username]);

                  return (
                    <Review
                      key={review.id}
                      handleClick={handleClick}
                      isChecked={isCheck.includes(review.id.toString())}
                      id={review.id}
                      review_describe={review.review_describe}
                      products={review.products}
                      user={review.users}
                      published={review.published_at}
                      productId={review.products.id}
                    ></Review>
                  );
                })
              ) : (
                <></>
              )}
              {/* {fp.length > 0 ? (
              fp.map((review) => {
                return (
                  <Review
                    key={review.id}
                    handleClick={handleClick}
                    isChecked={isCheck.includes(review.id.toString())}
                    // name={product.Name}
                    price={review.products.price}
                    productId={review.products.id}
                    description={review.description}
                    category={review.category.name}
                    review={review.reviview_describe}
                    username={review.users.username}
                    points={review.points}
                    id={review.id}
                  />
                );
              })
            ) : (
              <>
                {!reviews ? (
                  <tbody className="text-sm">
                    <tr>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div className="flex items-center">
                          <label className="inline-flex"></label>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center text-gray-800">
                          <div className="font-medium text-light-blue-500"></div>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div>There are no reviews with the applied filters</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-medium text-gray-800"></div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500"></div>
                      </td>

                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div className="text-left font-medium text-green-500"></div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <></>
                )}
              </>
            )} */}
            </table>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-4">
        <Pagination
          current={currentPage}
          itemRender={itemRender}
          total={reviews.length}
          pageSize={reviewsPerPage}
          onChange={paginate}
        />
      </div>
    </>
  );
}

export default ReviewTable;
