import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import axios from "axios";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import ReviewHeader from "./ReviewHeader";

function UpdateReview(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [change, setChange] = useState(false);
  const [Review, setReview] = useState();
  const [users, setUsers] = useState();
  const [products, setProducts] = useState();

  const [publish, setPublish] = useState();
  const [upublish, setUPublish] = useState();

  const [review_describe, setReview_describe] = useState();
  const [ureview_describe, setUReview_describe] = useState();
  const [id, setid] = useState();

  const history = useHistory();
  useEffect(() => {
    axios
      .get(
        `https://adminapi.youjifresh.com/reviews/${props.location.state.id}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response.data, "THis is the data ");
        setUsers(response.data.users);
        setProducts(response.data.products);
        setReview(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setPublish(props.location.state.publish);
    setUPublish(props.location.state.publish);
    setReview_describe(props.location.state.review_describe);
    setUReview_describe(props.location.state.review_describe);
    setid(props.location.state.id);
  }, []);

  const mouseover = () => {
    document.getElementById(`${props.id}`).style.backgroundColor = "#F0F0F0";
  };
  const mouseout = () => {
    document.getElementById(`${props.id}`).style.backgroundColor = "white";
  };

  const UpdateReview = (id) => {
    return (e) => {
      var axios = require("axios");

      var data = JSON.stringify({
        published_at: upublish,
      });

      var config = {
        method: "put",
        url: `https://adminapi.youjifresh.com/reviews/${id}`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          message.success("Review Updated");
          setChange(false);
          setShowModal(false);
          history.push("/review");
        })
        .catch(function (error) {
          console.log(error);
          message.error(error.response.data.message);
        });
    };
  };

  const cancel = () => {
    setShowModal(false);
    setPublish(props.location.state.publish);
    setUPublish(props.location.state.publish);
    setReview_describe(props.location.state.review_describe);
    setUReview_describe(props.location.state.review_describe);
    if (change === true) {
      setUPublish(props.location.state.publish);

      setUReview_describe(props.location.state.review_describe);

      window.alert("All the changes will be discarded");

      setShowModal(false);
      message.warning("Changes discarded");

      setChange(false);
    }
    setUpdate(false);
  };

  return (
    <>
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <ReviewHeader
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0 mr-2">
                  <h1 className="text-2xl p-6 md:text-3xl text-gray-800 font-bold">
                    Update Review
                  </h1>
                </div>

                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                  {/* Delete button */}

                  {/* Dropdown */}
                  {/* <DateSelect /> */}
                  {/* Filter button */}

                  {/* Add customer button */}
                </div>
              </div>

              {/* Table */}
              <div className="relative p-6 flex-auto">
                <form id="form_add_prod">
                  <div class="flex flex-wrap -mx-2 mb-2">
                    {users?.map((user) => {
                      return (
                        <div class="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="form__review_username"
                          >
                            Username
                          </label>
                          <input
                            key={id}
                            class="appearance-none block text-clip resize-y overflow-hidden w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="form__review_username"
                            type="textarea"
                            value={user.username}
                            placeholder="UserName"
                            disabled
                          />
                        </div>
                      );
                    })}
                    {products?.map((product) => {
                      return (
                        <div class="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="form__review_product"
                          >
                            Product Name
                          </label>
                          <input
                            key={id}
                            class="appearance-none block text-clip resize-y overflow-hidden w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="form__review_product"
                            type="textarea"
                            value={product.Name}
                            placeholder="Name of the product"
                            disabled
                          />
                        </div>
                      );
                    })}
                    <div class="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_slug"
                      >
                        Review
                      </label>
                      <textarea
                        class="appearance-none block text-clip resize-y overflow-hidden w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_description"
                        type="textarea"
                        value={review_describe}
                        placeholder="description of the product"
                        disabled
                      />
                    </div>

                    <div class="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_slug"
                      >
                        Publish Status
                      </label>
                      <select
                        value={publish ? publish : upublish}
                        onChange={(e) => {
                          setUpdate(true);

                          setChange(true);

                          setPublish();
                          if (e.target.value === "Unpublish") {
                            setUPublish(null);
                          } else if (e.target.value === "publish") {
                            const d = new Date();
                            setUPublish(d);
                          }
                          console.log(e.target.value);
                        }}
                      >
                        <option value="publish" selected={publish !== null}>
                          Publish
                        </option>
                        <option value="Unpublish" selected={publish === null}>
                          Unpublish
                        </option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>

              <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
                <div className="justify-items-end ">
                  <button
                    className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
                    onClick={cancel}
                  >
                    <span className="hidden xs:block">Cancel</span>
                  </button>
                  {update ? (
                    <button
                      className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                      onClick={UpdateReview(id)}
                    >
                      <span className="hidden xs:block">Update</span>
                    </button>
                  ) : (
                    <button
                      className="btn bg-yellow-500 opacity-50 text-white"
                      disabled
                    >
                      <span className="hidden xs:block">Update</span>
                    </button>
                  )}
                </div>
              </div>

              {/* Pagination */}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default UpdateReview;
