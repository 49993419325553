import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { message } from 'antd';
import Header from '../Header';
import Sidebar from '../Sidebar';
import AddHeader from '../AddHeader';

function AddCategory() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [categories,setCategories] = useState([]);
  
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState("");
    const [slug,setSlug] = useState()
  
    const handleSelectedItems = (selectedItems) => {
      setSelectedItems([...selectedItems]);
    };
    useEffect(()=>{
      setName('')
      setSlug('')
    },[])
  
    const AddProduct = (e) => {
      e.preventDefault();
      if(name===''||slug===''){
        message.error('Empty input')
      }
      else if(categories.map(c=>c.slug).includes(slug)){
        message.error('Slug value already exists, Change its value')
      }
      else{
      var axios = require("axios");
      var data = JSON.stringify({
        name:name?name:'',
        slug:slug?slug:''
      });
  
      var config = {
        method: "post",
        url: "https://adminapi.youjifresh.com/categories",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json"
        },
        data: data
      };
  
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          message.success("Category Added");
          setTimeout(() => {
            window.location.reload()
          }, 500);
        })
        .catch(function (error) {
          console.log(error);
          message.error(error.response.data.message);
        });
        setShowModal(false);
      }
    };
  
  
    const clearForm = () => {
      setName("");
      setSlug('')
    };
  
    const cancel = () => {
      setShowModal(false)
      
    }
  
    useEffect(() => {
      axios
        .get("https://adminapi.youjifresh.com/categories", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            "Content-Type": "application/json"
          }
        })
        .then(function (response) {
          console.log(response.data);
          setCategories(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }, []);
    // <button onClick={()=>{window.location.reload()}}>Back</button>
    return (
        <>
            <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        
        <AddHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Add Category
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Delete button */}
            
                {/* <DateSelect /> */}
                {/* Filter button */}

                {/* Add customer button */}
               
              </div>
            </div>
        

            {/* Table */}
            <div className="relative  flex-auto">
              <form id="form_add_prod">
                    <div class="flex flex-wrap -mx-2 mb-2">
      
                       <div class="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="form__product_name"
                        >
                          NAME
                        </label>
                        <input
                          class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_name"
                          type="text"
                          value={name?name:''}
                          onChange={(e) => {
                            setName(e.target.value);
                            console.log(e.target.value);
                          }}
                          placeholder="name"
                        />
                      </div>
      
                      <div class="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="form__product_slug"
                        >
                          SLUG
                        </label>
                        <input
                          class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_slug"
                          type="text"
                          value={slug?slug:''}
                          onChange={(e) => {
                            setSlug(e.target.value);
                            console.log(e.target.value);
                          }}
                          placeholder="slug"
                        />
                      </div>
      
              
                    </div>
                  </form>
                </div>

                <div className="flex items-center   border-solid border-blueGray-200 rounded-b">
                <div className="justify-items-end ">
               <button
                className="btn  content-start bg-gray-400 hover:bg-gray-600 text-white mr-2"
                onClick={clearForm}
              >
                <span className="hidden xs:block">Clear</span>
              </button>
              <button
                className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                onClick={AddProduct}
              >
                <span className="hidden xs:block">
                  Add
                </span>
              </button>
            </div>
            </div>

            {/* Pagination */}
           
          </div>

          
        </main>
      </div>
    </div>
       
                </>
    )
}

export default AddCategory
