import React, { useState, useEffect } from "react";
import { message } from "antd";
import { useHistory } from "react-router-dom";

function ReviewTableItem(props) {
  // console.log(props.productId, "this is he username");
  const [showModal, setShowModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [change, setChange] = useState(false);

  const [publish, setPublish] = useState();
  const [upublish, setUPublish] = useState();

  const [review_describe, setReview_describe] = useState();
  const [ureview_describe, setUReview_describe] = useState();
  const [id, setid] = useState();

  const history = useHistory();

  useEffect(() => {
    setPublish(props.published);
    setUPublish(props.published);
    setReview_describe(props.review_describe);
    setUReview_describe(props.review_describe);
    setid(props.id);
  }, []);

  const mouseover = () => {
    document.getElementById(`${props.id}`).style.backgroundColor = "#F0F0F0";
  };
  const mouseout = () => {
    document.getElementById(`${props.id}`).style.backgroundColor = "white";
  };

  const UpdateReview = (id) => {
    return (e) => {
      var axios = require("axios");

      var data = JSON.stringify({
        published_at: upublish,
      });

      var config = {
        method: "put",
        url: `https://adminapi.youjifresh.com/reviews/${id}`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          message.success("Review Updated");
          setChange(false);
          setShowModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch(function (error) {
          console.log(error);
          message.error(error.response.data.message);
        });
    };
  };

  return (
    <>
      <tbody className="text-sm">
        <tr
          style={{ cursor: "pointer" }}
          id={props.id}
          onMouseOver={mouseover}
          onMouseOut={mouseout}
          onClick={() =>
            history.push("/updateReview", { id, review_describe, publish })
          }
        >
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="flex items-center text-gray-800">
              <div className="font-medium text-light-blue-500">{props.id}</div>
            </div>
          </td>
          {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="text-left font-medium text-yellow-500">
              {props.user.map(u=>u.username)}
            </div>
          </td> */}
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
            <div>{props.review_describe}</div>
          </td>
          {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-medium text-gray-800">
            {props.products.map(p=>p.Name)}
            </div>
          </td> */}

          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-medium text-gray-800">
              {props.published != null ? "Published" : "Draft"}
            </div>
          </td>
        </tr>
      </tbody>

      {/* {showModal ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden  overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl ">
        
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
           
                      <div className="flex items-start justify-between p-5 border-b border-solid bg-gray-800 border-blueGray-200 rounded-t">
                        <h3 className="text-3xl font-semibold text-gray-400">
                          Update Category
                        </h3>
                        <button
                          className="p-1 ml-auto bg-transparent border-0 text-red-100 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          onClick={() => setShowModal(false)}
                        >
                          <span className="bg-transparent text-blue-600 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                          </span>
                        </button>
                      </div>
             
                      <div className="relative p-6 flex-auto">
                        <form class="w-full max-w-lg" id="form_add_prod">
                          <div class="flex flex-wrap -mx-2 mb-2">

                            <div class="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__product_slug"
                              >
                                Review
                              </label>
                              <textarea
                                class="appearance-none block text-clip resize-y overflow-hidden w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__product_description"
                                type="textarea"
                                value={props.review_describe} 
                                placeholder="description of the product"
                              />
                            </div>


                            <div class="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__product_slug"
                              >
                                Publish Status
                              </label>
                              <select 
                              value={publish?publish:upublish}
                                onChange={(e) => {
                                  setUpdate(true);
                                  
                                    setChange(true);
                                  
                                  setPublish();
                                  if(e.target.value==='Unpublish'){
                                    setUPublish(null)
                                  }else if(e.target.value==='publish'){
                                    const d = new Date();
                                    setUPublish(d)
                                  }
                                  console.log(e.target.value);
                                }}>
                                <option value='publish' selected={props.published!==null}>Published</option>
                                <option value='Unpublish' selected={props.published===null}>Unpublished</option>
                              </select>
                            </div>

                         

                    
                          </div>
                        </form>
                      </div>
                    
                      <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
                        
                        <div className="justify-items-end ">
                        <button
                      className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
                      onClick={()=>{setShowModal(false)}}
                    >
                      <span className="hidden xs:block">Cancel</span>
                    </button>
                        {update ? (
                      <button
                        className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                        onClick={UpdateReview(props.id)}
                      >
                        <span className="hidden xs:block">Update</span>
                      </button>
                    ) : (
                      <button className="btn bg-yellow-500 opacity-50 text-white" disabled>
                        <span className="hidden xs:block">Update</span>
                      </button>
                    )}
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null} */}
    </>
  );
}

export default ReviewTableItem;
