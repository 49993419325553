import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import "./css/style.scss";

import { focusHandling } from "cruip-js-toolkit";
import "./charts/ChartjsConfig";

// Import pages

import Dashboard from "./pages/Dashboard";
import Analytics from "./pages/Analytics";
import Customers from "./pages/ecommerce/Customers";
import Orders from "./pages/Orders";
import Invoices from "./pages/ecommerce/Invoices";
import Shop from "./pages/ecommerce/Shop";
import Shop2 from "./pages/ecommerce/Shop2";
import Product from "./pages/ecommerce/Product";
import Cart from "./pages/ecommerce/Cart";
import Cart2 from "./pages/ecommerce/Cart2";
import Pay from "./pages/ecommerce/Pay";
import Campaigns from "./pages/Campaigns";
import TeamTabs from "./pages/team/TeamTabs";
import TeamTiles from "./pages/team/TeamTiles";
import Profile from "./pages/team/Profile";
import Messages from "./pages/Messages";
import Tasks from "./pages/Tasks";
import Inbox from "./pages/Inbox";
import Calendar from "./pages/Calendar";
import Account from "./pages/settings/Account";
import Notifications from "./pages/settings/Notifications";
import Apps from "./pages/settings/Apps";
import Plans from "./pages/settings/Plans";
import Billing from "./pages/settings/Billing";
import Feedback from "./pages/settings/Feedback";
import Changelog from "./pages/utility/Changelog";
import Roadmap from "./pages/utility/Roadmap";
import Faqs from "./pages/utility/Faqs";
import EmptyState from "./pages/utility/EmptyState";
import PageNotFound from "./pages/utility/PageNotFound";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import Onboarding01 from "./pages/Onboarding01";
import Onboarding02 from "./pages/Onboarding02";
import Onboarding03 from "./pages/Onboarding03";
import Onboarding04 from "./pages/Onboarding04";
import ButtonPage from "./pages/component/ButtonPage";
import FormPage from "./pages/component/FormPage";
import DropdownPage from "./pages/component/DropdownPage";
import AlertPage from "./pages/component/AlertPage";
import ModalPage from "./pages/component/ModalPage";
import PaginationPage from "./pages/component/PaginationPage";
import TabsPage from "./pages/component/TabsPage";
import BreadcrumbPage from "./pages/component/BreadcrumbPage";
import BadgePage from "./pages/component/BadgePage";
import AvatarPage from "./pages/component/AvatarPage";
import TooltipPage from "./pages/component/TooltipPage";
import AccordionPage from "./pages/component/AccordionPage";
import IconsPage from "./pages/component/IconsPage";
import FreeShipping from "./pages/FreeShippping";
import Points from "./pages/Points";
import Products from "./pages/Products";
import Users from "./pages/Users";
import Category from "./pages/Category";
import Coupon from "./pages/Coupon";
import Reviews from "./pages/Review";
import MediaLibrary from "./pages/MediaLibrary";
import ProtectedRoute from "./components/ProtectedRoute";
import UpdateCategory from "./partials/categories/UpdateCategory";
import UpdateCoupon from "./partials/coupons/UpdateCoupon";
import UpdateReview from "./partials/review/UpdateReview";
import UpdateOrder from "./partials/orders/UpdateOrder";
import UpdateProduct from "./partials/products/UpdateProducts";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Signin />
        </Route>
        <ProtectedRoute
          component={Dashboard}
          exact
          path="/dashboard"
        ></ProtectedRoute>
        {/* <Route exact path="/analytics">
          <Analytics />
        </Route>
        <Route exact path="/ecommerce/customers">
          <Customers />
        </Route> */}
        <ProtectedRoute
          component={Orders}
          exact
          path="/orders"
        ></ProtectedRoute>
        <ProtectedRoute
          component={UpdateCategory}
          exact
          path="/updateCategory"
        ></ProtectedRoute>
        <ProtectedRoute
          component={UpdateCoupon}
          exact
          path="/updateCoupon"
        ></ProtectedRoute>
        <ProtectedRoute
          component={UpdateReview}
          exact
          path="/updateReview"
        ></ProtectedRoute>
        <ProtectedRoute
          component={UpdateOrder}
          exact
          path="/updateOrder"
        ></ProtectedRoute>
        <ProtectedRoute
          component={UpdateProduct}
          exact
          path="/updateProduct"
        ></ProtectedRoute>
        {/* <Route exact path="/ecommerce/invoices">
          <Invoices />
        </Route>
        <Route exact path="/ecommerce/shop">
          <Shop />
        </Route>
        <Route exact path="/ecommerce/shop-2">
          <Shop2 />
        </Route>
        <Route exact path="/ecommerce/product">
          <Product />
        </Route>
        <Route exact path="/ecommerce/cart">
          <Cart />
        </Route>
        <Route exact path="/ecommerce/cart-2">
          <Cart2 />
        </Route>
        <Route exact path="/ecommerce/pay">
          <Pay />
        </Route>

        <Route exact path="/campaigns">
          <Campaigns />
        </Route> */}
        <ProtectedRoute
          exact
          path="/free-shipping"
          component={FreeShipping}
        ></ProtectedRoute>
        <ProtectedRoute
          exact
          path="/points"
          component={Points}
        ></ProtectedRoute>
        <ProtectedRoute
          exact
          path="/products"
          component={Products}
        ></ProtectedRoute>

        <ProtectedRoute exact path="/users" component={Users}></ProtectedRoute>
        <ProtectedRoute
          exact
          path="/categories"
          component={Category}
        ></ProtectedRoute>
        <ProtectedRoute
          exact
          path="/coupons"
          component={Coupon}
        ></ProtectedRoute>

        <ProtectedRoute
          exact
          path="/medialibrary"
          component={MediaLibrary}
        ></ProtectedRoute>

        <ProtectedRoute
          exact
          path="/review"
          component={Reviews}
        ></ProtectedRoute>
        {/* <Route exact path="/team/team-tabs">
          <TeamTabs />
        </Route>
        <Route exact path="/team/team-tiles">
          <TeamTiles />
        </Route>
        <Route exact path="/team/profile">
          <Profile />
        </Route>
        <Route exact path="/messages">
          <Messages />
        </Route>
        <Route exact path="/tasks">
          <Tasks />
        </Route>
        <Route exact path="/inbox">
          <Inbox />
        </Route>
        <Route exact path="/calendar">
          <Calendar />
        </Route>
        <Route exact path="/settings/account">
          <Account />
        </Route>
        <Route exact path="/settings/notifications">
          <Notifications />
        </Route>
        <Route exact path="/settings/apps">
          <Apps />
        </Route>
        <Route exact path="/settings/plans">
          <Plans />
        </Route>
        <Route exact path="/settings/billing">
          <Billing />
        </Route>
        <Route exact path="/settings/feedback">
          <Feedback />
        </Route>
        <Route exact path="/utility/changelog">
          <Changelog />
        </Route>
        <Route exact path="/utility/roadmap">
          <Roadmap />
        </Route>
        <Route exact path="/utility/faqs">
          <Faqs />
        </Route>
        <Route exact path="/utility/empty-state">
          <EmptyState />
        </Route>
        <Route exact path="/utility/404">
          <PageNotFound />
        </Route> */}
        <Route exact path="/signin">
          <Signin />
        </Route>
        {/* <Route exact path="/signup">
          <Signup />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/onboarding-01">
          <Onboarding01 />
        </Route>
        <Route exact path="/onboarding-02">
          <Onboarding02 />
        </Route>
        <Route exact path="/onboarding-03">
          <Onboarding03 />
        </Route>
        <Route exact path="/onboarding-04">
          <Onboarding04 />
        </Route>
        <Route exact path="/component/button">
          <ButtonPage />
        </Route>
        <Route exact path="/component/form">
          <FormPage />
        </Route>
        <Route exact path="/component/dropdown">
          <DropdownPage />
        </Route>
        <Route exact path="/component/alert">
          <AlertPage />
        </Route>
        <Route exact path="/component/modal">
          <ModalPage />
        </Route>
        <Route exact path="/component/pagination">
          <PaginationPage />
        </Route>
        <Route exact path="/component/tabs">
          <TabsPage />
        </Route>
        <Route exact path="/component/breadcrumb">
          <BreadcrumbPage />
        </Route>
        <Route exact path="/component/badge">
          <BadgePage />
        </Route>
        <Route exact path="/component/avatar">
          <AvatarPage />
        </Route>
        <Route exact path="/component/tooltip">
          <TooltipPage />
        </Route>
        <Route exact path="/component/accordion">
          <AccordionPage />
        </Route>
        <Route exact path="/component/icons">
          <IconsPage />
        </Route> */}

        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
}

export default App;
