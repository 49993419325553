import React, { useState } from "react";

const ProductImage = ({
  name,
  id,
  thumbnailUrl,
  alternateText,
  setPhotoId,
  setPhotoModal,
  setUrl,
  asset,
  setImage,
  handleMultiplePhoto,
}) => {
  const handleImageClick = () => {
    setPhotoId(id);
    handleMultiplePhoto(asset)
    setUrl(thumbnailUrl);
    // setImage(asset);
    console.log(asset);
  };
  return (
    <div>
      {/* <div className="max-w-2xl mx-auto py-8 px-2 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-6"> */}
      <div className=" flex flex-rowgap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
        <div key={id} className="group">
          <div className="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
            <label for={id} name={id}>
              <img
                src={thumbnailUrl}
                alt={alternateText}
                onClick={() => handleImageClick(asset)}
                className="w-full h-full object-center object-cover group-hover:opacity-75"
              />
            </label>
          </div>
          <h3 className="mt-4 text-sm text-gray-700">
            <input
              id={id}
              className="form-checkbox"
              type="checkbox"
              // onChange={handleClick}
              // checked={isChecked}
            />
            {name}
          </h3>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ProductImage;
