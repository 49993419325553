import React, { useState, useEffect } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import axios from "axios";
import ProductImageListing from "../components/ProductImageListing";
import _ from "loadsh";
import { message, Carousel } from "antd";
import AddHeader from "../partials/AddHeader.js";

var slugify = require("slugify");

function AddProducts() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [stock, setStock] = useState();
  const [slug, setSlug] = useState();
  const [meta_title, setMeta_title] = useState();
  const [meta_description, setMeta_description] = useState();
  const [shipping, setShipping] = useState();
  const [order_cutoff_time, setOrder_cutoff_time] = useState();
  const [storage, setStorage] = useState();
  const [onsale, setOnsale] = useState();
  const [catid, setCatid] = useState();

  const [cat, setCat] = useState([]);
  const [Files, setFiles] = useState([]);

  const [Image, setImage] = useState([]);
  const [PhotoId, setPhotoId] = useState("");
  const [PhotoModal, setPhotoModal] = useState(false);
  const [Url, setUrl] = useState("");
  const [Photo, setPhoto] = useState([]);
  const [uploadPhotoModal, setUploadPhotoModal] = useState();
  const [loading, setLoading] = useState(false);
  const [bestSeller, setBestSeller] = useState();
  const formData = new FormData();

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const AddProduct = (e) => {
    e.preventDefault();
    if (
      name === "" ||
      description === "" ||
      price === undefined ||
      category === undefined
    ) {
      message.error("Please enter input values");
    } else if (products.map((p) => p.slug).includes(slug)) {
      message.error("Slug value already exists, Change its value");
    } else {
      var axios = require("axios");

      var data = JSON.stringify({
        Name: name ? name : "",
        price: price,
        description: description ? description : "",
        category: category,
        stocks: stock ? stock : null,
        slug: slug,
        meta_title: meta_title,
        meta_description: meta_description,
        shipping: shipping,
        order_cut_off_time: order_cutoff_time,
        storage: storage,
        OnSale: onsale ? onsale : null,
        photo: Photo,
        bestseller: bestSeller,
      });

      var config = {
        method: "post",
        url: "https://adminapi.youjifresh.com/products",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          message.success("Product Added");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch(function (error) {
          console.log(error);
          message.error(error.response.data.message);
        });
      setShowModal(false);
    }
  };

  const handleClearPhoto = (e) => {
    e.preventDefault();
    setPhotoId("");
    setPhoto([]);
    setImage([]);
  };

  const cancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    axios
      .get(`https://adminapi.youjifresh.com/categories`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setCat(response.data);
        console.log(cat);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var axios = require("axios");

    var config = {
      method: "get",
      url: "https://adminapi.youjifresh.com/products?_limit=-1",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setName(response.data.Name);
        setPrice(response.data.price);
        setCategory(response.data.category);
        setDescription(response.data.description);
        setStock(response.data.stock);
        setSlug(response.data.slug);
        setMeta_title(response.data.meta_title);
        setMeta_description(response.data.meta_description);
        setShipping(response.data.shipping);
        setOrder_cutoff_time(response.data.order_cut_off_time);
        setStorage(response.data.storage);
        setOnsale(response.data.OnSale);
        setCatid(response.data.catid);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const clearForm = () => {
    setName("");
    setPrice("");
    setCategory("");
    setDescription("");
    setStock("");
    setSlug("");
    setMeta_title("");
    setMeta_description("");
    setShipping("");
    setOrder_cutoff_time("");
    setStorage("");
    setOnsale("");
    setImage("");
  };

  useEffect(() => {
    axios
      .get("https://adminapi.youjifresh.com/products?_limit=-1", {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);
        setProducts(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handlePhotoModalChange = (e) => {
    e.preventDefault();
    setPhotoModal(true);
  };

  const handleMultiplePhoto = (asset) => {
    for (let i = 0; i < 10; i++) {
      if (Photo[i] == null) {
        Photo[i] = asset;
        console.log(asset);
        break;
      }
    }
    console.log("photo", Photo);
    setImage(Photo);
  };

  const contentStyle = {
    height: "auto",
    color: "#fff",
    width: "100%",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  //   const handleFileUpload = (e) => {
  //     e.preventDefault();
  //     setFiles(e.target.files);
  //     const formData = new FormData();

  //     console.log(Files);
  //     _.forEach(Files, (file) => {
  //       formData.append("files", file);
  //       console.log(file);
  //     });
  //   };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    // console.log("File upload ", files);
    const formData = new FormData();
    _.forEach(Files, (file) => {
      formData.append("files", file);
      // console.log(file);
    });
    console.log(formData);
    axios({
      method: "post",
      url: "https://adminapi.youjifresh.com/upload/",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
        "Content-Type": "application/json",
      },
      data: formData,
    })
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        message.success("Asset Added");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 500);
        setPhotoModal(false);
        setLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
        message.error(error.response.data.message);
      });
  };

  const handleUploadPhoto = (e) => {
    e.preventDefault();
    setFiles(e.target.value);
  };

  // <button onClick={()=>{window.location.reload()}}>Back</button>
  return (
    <>
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}

          <AddHeader
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full  mx-auto">
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                {/* <div className="justify-center items-center flex overflow-x-hidden  overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl ">  */}

                {/*content*/}
                {/* <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-screen"> */}
                {/*header*/}
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                    Add products
                  </h1>
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                  {/* Delete button */}

                  {/* <DateSelect /> */}
                  {/* Filter button */}

                  {/* Add customer button */}
                </div>
              </div>

              {/*body*/}
              <div className="relative  flex-auto ">
                <form id="form_add_prod">
                  <div class="flex flex-wrap -mx-2 mb-2">
                    <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_name"
                      >
                        NAME
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_name"
                        type="text"
                        // value={name}
                        onBlur={e => {
                          setName(e.target.value);
                          setSlug(slugify(e.target.value));
                          console.log(e.target.value);
                        }}
                        placeholder="name"
                      />
                    </div>

                    <div class="w-full md:w-2/3 px-2  mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_description"
                      >
                        DESCRIPTION
                      </label>
                      <textarea
                        class="appearance-none block text-clip resize-y overflow-hidden w-full bg-gray-200 h-24 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_description"
                        type="textarea"
                        rows={6}
                        //value={description ? description : ""}
                        onBlur={(e) => {
                          setDescription(e.target.value);
                          console.log(e.target.value);
                        }}
                        placeholder="description of the product"
                      />
                    </div>
                    <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_price"
                      >
                        PRICE ($)
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_price"
                        type="number"
                        step="0.01"
                        onScroll={document.activeElement.blur()}
                        //value={price}
                        onBlur={(e) => {
                          setPrice(e.target.value);
                          console.log(e.target.value);
                        }}
                        placeholder="$123"
                      />
                    </div>
                    <span></span>
                    <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_category"
                      >
                        CATEGORY
                      </label>
                      <div class="relative">
                        <select
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_category"
                          value={category}
                          onBlur={(e) => {
                            setCategory(e.target.value);
                            console.log(e.target.value);
                          }}
                        >
                          <option value={-1}>select a category</option>
                          {cat.map((c) => (
                            <option value={c.id}>{c.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      {/* Photo adding */}

                      {/* If have Id display image and clear button else borken image with add image button */}

                      <div className="w-75">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 "
                          for="form__product_photo"
                        >
                          PHOTO
                        </label>
                        <div class="relative">
                          <Carousel autoplay>
                            {Image
                              ? Image.map((p) => (
                                  <div>
                                    <h3 style={contentStyle}>
                                      <img
                                        src={p.formats.thumbnail.url}
                                        style={{
                                          objectFit: "contain",
                                        }}
                                        height="100px"
                                        alt="ProductImage"
                                      />
                                    </h3>
                                  </div>
                                ))
                              : null}
                          </Carousel>
                        </div>
                        <div class="relative">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="form__product_clear"
                          >
                            {PhotoId ? (
                              <div className="flex">
                                <button
                                  className="btn bg-yellow-500 w-full mr-5  hover:bg-yellow-600 text-white"
                                  onClick={handleClearPhoto}
                                >
                                  <span className=" xs:block">clear</span>
                                </button>
                                <button
                                  className="btn bg-yellow-500 w-full  hover:bg-yellow-600 text-white"
                                  onClick={handlePhotoModalChange}
                                >
                                  <span className=" xs:block">Add</span>
                                </button>
                              </div>
                            ) : (
                              <>
                                {/* <input
                                  type="file"
                                  multiple
                                  accept="image/*"
                                  className="btn bg-yellow-500 w-full  hover:bg-yellow-600 text-white"
                                  onChange={(e) => {
                                    setFiles(e.target.files);
                                  }}
                                />
                                <span className="hidden xs:block">upload</span>
                                <button
                                  className="btn bg-yellow-500 w-full  hover:bg-yellow-600 text-white my-4"
                                  onClick={handleSubmit}
                                >
                                  <span className="hidden xs:block">
                                    Upload [Media Library]
                                  </span>
                                </button> */}
                                <button
                                  className="btn bg-yellow-500 w-full  hover:bg-yellow-600 text-white"
                                  onClick={handlePhotoModalChange}
                                >
                                  <span className="hidden xs:block">
                                    Add to product
                                  </span>
                                </button>
                              </>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <div>
                                <div class="relative">
                                  <label
                                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="form__product_name"
                                  >
                                    <button
                                      className="btn bg-yellow-500 w-full  hover:bg-yellow-600 text-white"
                                      onClick={handlePhotoModalChange}
                                    >
                                      <span className="hidden xs:block">
                                        Add
                                      </span>
                                    </button>
                                  </label>
                                </div>
                              </div> */}

                    {/* modal to display all the images in media library */}

                    {PhotoModal ? (
                      <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                          <div className="relative w-auto md:w-full my-6 mx-auto max-w-6xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-screen">
                              {/*header*/}
                              <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                <h3 className="text-2xl md:text-3xl md:items-cfont-semibold">
                                  Photo Select
                                </h3>
                                {loading ? (
                                  <div className="flex justify-center items-center p-10">
                                    <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-black-600"></div>
                                  </div>
                                ) : null}
                                <span className="hidden xs:block">
                                  <input
                                    className="left-0 item-align:right"
                                    multiple
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                      setFiles(e.target.files);
                                    }}
                                  />
                                  <button
                                    className=" w-25 pr-10 btn bg-yellow-500 hover:bg-yellow-600 text-white"
                                    onClick={handleSubmit}
                                  >
                                    <svg
                                      className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                                    </svg>{" "}
                                    Upload Assets
                                  </button>
                                </span>
                              </div>

                              {/*body*/}
                              <div className="relative p-6 flex-auto overflow-y-scroll">
                                {loading ? (
                                  <ProductImageListing
                                    selectedItems={handleSelectedItems}
                                    setPhotoId={setPhotoId}
                                    setPhotoModal={setPhotoModal}
                                    setUrl={setUrl}
                                    setImage={setImage}
                                    handleMultiplePhoto={handleMultiplePhoto}
                                  />
                                ) : (
                                  <ProductImageListing
                                    selectedItems={handleSelectedItems}
                                    setPhotoId={setPhotoId}
                                    setPhotoModal={setPhotoModal}
                                    setUrl={setUrl}
                                    setImage={setImage}
                                    handleMultiplePhoto={handleMultiplePhoto}
                                  />
                                )}
                                {/* <ProductImageListing
                                  selectedItems={handleSelectedItems}
                                  setPhotoId={setPhotoId}
                                  setPhotoModal={setPhotoModal}
                                  setUrl={setUrl}
                                  setImage={setImage}
                                  handleMultiplePhoto={handleMultiplePhoto}
                                /> */}
                              </div>
                              {/*footer*/}
                              <div className="flex items-center justify-end p-6 border-t border-solid  border-blueGray-200 rounded-b">
                                <button
                                  className="text-red-500  font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  type="button"
                                  onClick={() => setPhotoModal(false)}
                                >
                                  Close
                                </button>
                                <button
                                  className="text-red-500  font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  type="button"
                                  onClick={handleClearPhoto}
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                      </>
                    ) : null}

                    {/* <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0 ">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__product_image"
                              >
                                PHOTO
                              </label>
                              <div class="relative">
                                <label
                                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                  for="form__product_name"
                                >
                                  <button
                                    className="btn bg-yellow-500 w-full  hover:bg-yellow-600 text-white"
                                    // onClick={AddPhoto}
                                  >
                                    <span className="hidden xs:block">Add</span>
                                  </button>
                                </label>
                              </div>
                            </div> */}

                    <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_stock"
                      >
                        STOCK
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_stock"
                        type="number"
                        //value={stock}
                        onBlur={(e) => {
                          setStock(e.target.value);
                          console.log(e.target.value);
                        }}
                        placeholder="stock"
                      />
                    </div>
                    <div class="w-full md:w-2/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_slug"
                      >
                        SLUG
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_slug"
                        type="text"
                        value={slug}
                        onChange={(e) => {
                          setSlug(e.target.value);
                          console.log(e.target.value);
                        }}
                        placeholder="slug"
                      />
                    </div>
                    <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_onsale"
                      >
                        Onsale [Offer]
                      </label>
                      <div className="relative">
                        <select
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_onsale"
                          value={onsale}
                          onBlur={(e) => {
                            setOnsale(e.target.value);
                            console.log(e.target.value);
                          }}
                        >
                          <option value={-1}>select an option</option>
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </select>
                      </div>
                    </div>

                    <div class="w-full md:w-4/6  px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_meta_title"
                      >
                        META TITLE
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_meta_title"
                        type="text"
                       // value={meta_title}
                        onBlur={(e) => {
                          setMeta_title(e.target.value);
                          console.log(e.target.value);
                        }}
                        placeholder="meta title"
                      />
                    </div>

                    <div class="w-full md:w-4/6 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_meta_description"
                      >
                        META DESCRIPTION
                      </label>
                      <textarea
                        class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_meta_description"
                        type="text"
                        rows={3}
                        //value={meta_description}
                        onBlur={(e) => {
                          setMeta_description(e.target.value);
                          console.log(e.target.value);
                        }}
                        placeholder="meta description"
                      />
                    </div>
                    <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_bestseller"
                      >
                        Best Seller
                      </label>
                      <div className="relative">
                        <select
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_bestseller"
                          value={bestSeller}
                          onBlur={(e) => {
                            setBestSeller(e.target.value);
                            console.log(e.target.value);
                          }}
                        >
                          <option value={-1}>select an option</option>
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </select>
                      </div>
                    </div>

                    {/* <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_o"
                      >
                        ORDER CUTOFF TIME
                      </label>
                      <textarea
                        class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_o"
                        type="text"
                        value={order_cutoff_time}
                        onBlur={(e) => {
                          setOrder_cutoff_time(e.target.value);
                          console.log(e.target.value);
                        }}
                        placeholder="order cutoff time"
                      />
                    </div>

                    <div class="w-full md:w-2/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_storage"
                      >
                        STORAGE
                      </label>
                      <textarea
                        class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_storage"
                        rows={3}
                        type="text"
                        value={storage}
                        onBlur={(e) => {
                          setStorage(e.target.value);
                          console.log(e.target.value);
                        }}
                        placeholder="storage"
                      />
                    </div>
                    <div class="w-full md:w-1/3 px-2 mb-5 mt-2 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_shipping"
                      >
                        SHIPPING
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_shipping"
                        type="text"
                        value={shipping}
                        onBlur={(e) => {
                          setShipping(e.target.value);
                          console.log(e.target.value);
                        }}
                        placeholder="shipping"
                      />
                    </div> */}
                  </div>
                </form>
              </div>
              {/*footer*/}
              <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  onClick={clearForm}
                  className=" btn  content-start bg-gray-400 hover:bg-gray-600 text-white mr-2"
                >
                  <span className=" xs:block">Clear</span>
                </button>
                <div className="justify-items-end ">
                  <button
                    className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
                    onClick={() => {
                      setPhoto([]);
                      setShowModal(false);
                    }}
                  >
                    <span className=" xs:block">Cancel</span>
                  </button>
                  <button
                    className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                    onClick={AddProduct}
                  >
                    <span className=" xs:block">Add</span>
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default AddProducts;
