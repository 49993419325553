import React, { useState,useEffect } from "react";
import ReactTooltip from "react-tooltip";
import {message} from 'antd'
import { useHistory } from "react-router-dom";
import UpdateCategory from "./UpdateCategory";

function CategoryTableItem(props) {
  const history = useHistory()
  const value = props.products.map((p) => `  ${p.Name}  `);
  const [showModal,setShowModal]=useState(false)

  const [name,setName] = useState()
  const [slug,setSlug] = useState()
  const [id,setId]=useState()

  const [uname,setUName] = useState()
  const [uslug,setUSlug] = useState()

  const [update,setUpdate] = useState()
  const [change,setChange] = useState()


  useEffect(()=>{
    setName(props.name)
    setSlug(props.slug)
    setId(props.id)

    setUName(props.name)
    setUSlug(props.slug)
  },[])

  const mouseover = () => {
    document.getElementById(`${props.name}`).style.backgroundColor='#F0F0F0'
  }
  const mouseout = () => {
    document.getElementById(`${props.name}`).style.backgroundColor='white'
  }
  
  const UpdateCategory = (id) => {
    return e => {
      e.preventDefault()
      if(uname===''||uslug===''){
        message.error('Empty input')
      }
      else{
      var axios = require("axios");
  
      var data = JSON.stringify({
       name:uname?uname:name,
       slug:uslug?uslug:slug
      });
  
      var config = {
        method: "put",
        url: `https://adminapi.youjifresh.com/categories/${id}`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
        data: data,
      };
  
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          message.success("Coupon Updated");
          setChange(false)
          setShowModal(false)
          setTimeout(() => {
            window.location.reload()
          }, 500);
         
        })
        .catch(function (error) {
          console.log(error);
          message.error(error.response.data.message);
        });
    }
  }
  }

  const cancel = () => {
    setShowModal(false)
    setName(props.name)
    setSlug(props.slug)

    setUName(props.name)
    setUSlug(props.slug)

    if(change===true){

   


      window.alert("All the changes will be discarded")

        setUName(props.name)
        setUSlug(props.slug)

        setShowModal(false)
        message.warning('Changes discarded')

      setChange(false)
    }
    setUpdate(false)
  }

  return (
    <>
    
      <tbody className="text-sm">
        <tr>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
            <div className="flex items-center">
              <label className="inline-flex">
                <span className="sr-only">Select</span>
                <input
                  id={props.id}
                  className="form-checkbox"
                  type="checkbox"
                  onChange={props.handleClick}
                  checked={props.isChecked}
                />
              </label>
            </div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="flex items-center text-gray-800">
              <div className="font-medium text-light-blue-500">{props.id}</div>
            </div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap" style={{'cursor':'pointer'}} id={props.name} onClick={() =>{ history.push('/updateCategory',{name,slug,uname,uslug,id})}} onMouseOver={mouseover} onMouseOut={mouseout}>
            <div>{props.name}</div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-medium text-gray-800">
              <div style={{ userSelect: "none" }}>
                <span
                  className="cursor-pointer"
                  data-event="click"
                  data-tip={value}
                >
                  {props.products && props.products.length} items
                </span>
              </div>
            </div>
          </td>
         
        </tr>
      </tbody>

      {/* {showModal ? (



              <>
                <div className="justify-center items-center flex overflow-x-hidden  overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl ">
             
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
           
                      <div className="flex items-start justify-between p-5 border-b border-solid bg-gray-800 border-blueGray-200 rounded-t">
                        <h3 className="text-3xl font-semibold text-gray-400">
                          Update Category
                        </h3>
                        <button
                          className="p-1 ml-auto bg-transparent border-0 text-red-100 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          onClick={() => setShowModal(false)}
                        >
                          <span className="bg-transparent text-blue-600 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                          </span>
                        </button>
                      </div>
           
                      <div className="relative p-6 flex-auto">
                        <form class="w-full max-w-lg" id="form_add_prod">
                          <div class="flex flex-wrap -mx-2 mb-2">

                            <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__product_name"
                              >
                                NAME
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__product_name"
                                type="text"
                                value={name?name:uname}
                                onChange={(e) => {
                                  setUpdate(true)
                                  if((name || uname)!==e.target.value){
                                    setChange(true)
                                  }
                                  setName();
                                  setUName(e.target.value)
                                  console.log(e.target.value);
                                }}
                                placeholder="name"
                              />
                            </div>

                            <div class="w-full md:w-1/3 px-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__product_slug"
                              >
                                SLUG
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__product_slug"
                                type="text"
                                value={slug?slug:uslug}
                                onChange={(e) => {
                                  setUpdate(true)
                                  if((slug || uslug)!==e.target.value){
                                    setChange(true)
                                  }
                                  setSlug();
                                  setUSlug(e.target.value)
                                  console.log(e.target.value);
                                }}
                                placeholder="slug"
                              />
                            </div>

                    
                          </div>
                        </form>
                      </div>
                     
                      <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
                        
                        <div className="justify-items-end ">
                          <button
                            className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
                            onClick={cancel}
                          >
                            <span className="hidden xs:block">Cancel</span>
                          </button>
                          {
                            update ? 
                            <button
                            className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                            onClick={UpdateCategory(props.id)}
                          >
                            <span className="hidden xs:block">
                              Update 
                            </span>
                          </button>: <button
                            className="btn bg-yellow-500 opacity-50 text-white"
                            disabled
                          >
                            <span className="hidden xs:block">
                              Update 
                            </span>
                          </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
           
            ) : null} */}

    </>
  );
}

export default CategoryTableItem;
