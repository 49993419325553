import React,{useState,useEffect} from 'react'
import axios from 'axios';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { DatePicker,message } from 'antd';
import AddHeader from '../AddHeader';

function AddCoupon() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [coupons, setCoupons] = useState([]);
  
    const [showModal, setShowModal] = useState(false);
    const [percentage,setPercentage]=useState()
    const [couponCode, setCouponCode] = useState("");
    const [value, setValue] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [active,setActive]=useState()
    const [maxValue,setMaxValue]=useState()
    const [minValue,setMinValue]=useState()
    const [usage,setUsage]=useState()
    const handleSelectedItems = (selectedItems) => {
      setSelectedItems([...selectedItems]);
      console.log(selectedItems.id);
    };
  
    console.log(expiryDate)
  
    useEffect(() => {
      axios
        .get("https://adminapi.youjifresh.com/coupons", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          console.log(response.data);
          setCoupons(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }, []);
  
  
    const AddCoupons = (e) => {
      e.preventDefault();
      if((percentage===undefined||percentage===-1)||(active===undefined||active===-1)||couponCode===''||value===undefined||(expiryDate===''||expiryDate===null)){
        message.error('Empty Field/s')
      }
      else if(coupons.map(c=>c.coupon_code).includes(couponCode)){
          message.error('A coupon with same code already exists')
      }
      else{
      var axios = require("axios");
      var data = JSON.stringify({
        Percentage:percentage,
        coupon_code: couponCode,
        Value: value,
        Expiry_Date: expiryDate,
        Active:active,
        Max_value:maxValue,
        usage : usage,
        min_cart_value:minValue
      });
  
      var config = {
        method: "post",
        url: "https://adminapi.youjifresh.com/coupons",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
        data: data,
      };
  
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          message.success("Coupon Added");
          setTimeout(() => {
            window.location.reload()
          }, 500);
        })
        .catch(function (error) {
          console.log(error);
          message.error(error.response.data.message);
        });
      setShowModal(false);
      setPercentage(-1)
      setCouponCode("");
      setValue("");
      setExpiryDate(null);
      setActive(-1)
      setMaxValue('')
    }
    };
  
    const clearForm = () => {
      setPercentage(-1)
      setCouponCode("");
      setValue("");
      setExpiryDate(null);
      setActive(-1)
      setMaxValue('')
    };
  
    
    return (
        <>
          <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <AddHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl p-6 md:text-3xl text-gray-800 font-bold">
                  Add Coupon
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Delete button */}
               
                {/* Dropdown */}
                {/* <DateSelect /> */}
                {/* Filter button */}

                {/* Add customer button */}
              
              </div>
            </div>
         

            {/* Table */}
                     <div className="relative px-4 flex-auto">
                         <form id="form_add_Coupon">
                           <div class="flex flex-wrap -mx-2 mb-2">
                             <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                               <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_percentage"
                              >
                                PERCENTAGE
                              </label>
                              <div className='relative'>
                            <select
                                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="form__coupon_percentage"
                                  value={percentage}
                                  onChange={(e) => {
                                    setPercentage(e.target.value);
                                    console.log(e.target.value);
                                  }}
                                >
                                  <option value={-1} >select an option</option>
                                  <option value={true} >True</option>
                                  <option value={false} >False</option>
                                  
                                  
                                </select>
                                </div>
                            </div>

                            <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_active"
                              >
                                Active
                              </label>
                              <div className='relative'>
                            <select
                                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="form__coupon_active"
                                  value={active}
                                  onChange={(e) => {
                                    setActive(e.target.value);
                                    console.log(e.target.value);
                                  }}
                                >
                                  <option value={-1} >select an option</option>
                                  <option value={true} >True</option>
                                  <option value={false} >False</option>
                                  
                                  
                                </select>
                                </div>
                            </div>

                            <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_code"
                              >
                                Coupon Code
                              </label>
                              <input
                                class="appearance-none block text-clip resize-y overflow-hidden w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__coupon_code"
                                type="text"
                                value={couponCode}
                                onChange={(e) => {
                                  setCouponCode(e.target.value);
                                  console.log(e.target.value);
                                }}
                                placeholder="Coupon Code"
                              />
                            </div>

                            <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_value"
                              >
                                Value
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__coupon_value"
                                type="number"
                                value={value}
                                onChange={(e) => {
                                  setValue(e.target.value);
                                  console.log(e.target.value);
                                }}
                                placeholder="ex. 10"
                              />
                            </div>
                            <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_mvalue"
                              >
                                Minimum Cart VALUE
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__coupon_mvalue"
                                type="number"
                                value={minValue ? minValue : 0}
                                onChange={(e) => {
                                  setMinValue(e.target.value);
                                  console.log(e.target.value);
                                }}
                                placeholder="ex. 10"
                              />
                            </div>
                            <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_mvalue"
                              >
                                USAGE/USER
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__coupon_mvalue"
                                type="number"
                                value={usage ? usage : 10}
                                onChange={(e) => {
                                  setUsage(e.target.value);
                                  console.log(e.target.value);
                                }}
                                placeholder="ex. 10"
                              />
                            </div>
                            <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_mvalue"
                              >
                                MAX VALUE
                              </label>
                              <input
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="form__coupon_mvalue"
                                type="number"
                                value={maxValue}
                                onChange={(e) => {
                                  setMaxValue(e.target.value);
                                  console.log(e.target.value);
                                }}
                                placeholder="ex. 10"
                              />
                            </div>
                          

                            <div class="w-full md:w-1/2 p-2 mb-5 mt-2 md:mb-0">
                              <label
                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="form__coupon_exp_date"
                              >
                                Expiry Date
                              </label>
                              <div class="relative">
                                <DatePicker
                                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="form__coupon_exp_date"
                                  isclearable
                                  value={expiryDate}
                                  onChange={(expiryDate) =>
                                    setExpiryDate(expiryDate)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          onClick={clearForm}
                          className=" btn  content-start bg-gray-400 hover:bg-gray-600 text-white mr-2"
                        >
                          <span className="hidden xs:block">Clear</span>
                        </button>
                        <div className="justify-items-end ">
                         
                          <button
                            className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                            onClick={AddCoupons}
                          >
                            <span className="hidden xs:block">
                              Add
                            </span>
                          </button>
                        </div>
                      </div>

            {/* Pagination */}
            
          </div>
        </main>
      </div>
    </div>
        </>
    )
}

export default AddCoupon
